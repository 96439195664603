import { Fragment } from "react";
import ReactDOM from "react-dom";
import classes from "./Modal.module.css";
import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";

export const TheVideoModal = (props) => {
  console.log(props.textMessage);
  return (
    <div className={classes.backdrop}>
      <div className={`${classes.modal} ${classes.card}`}>
        <header className={classes.header}>
          <h2> {props.title} </h2>
        </header>
        <div className={classes.content}>
          <div className={` rounded bg-white  p-3 text-dark`}>
            {Object.keys(props.message).length ? (
              <>
                <h5 className="my-2">
                  <code className="text-dark">
                    Size In GBs:{" "}
                    <span className="text-success">
                      {props.message.sizeInGBs} gb
                    </span>
                  </code>
                </h5>
                <h5 className="">
                  <code className="text-dark">
                    Size In MBs:{" "}
                    <span className="text-success">
                      {props.message.sizeInMBs} mb{" "}
                    </span>
                  </code>
                </h5>
              </>
            ) : (
              ""
            )}
            <h5 className="my-2 text-center text-danger">
              {props.textMessage}
            </h5>
          </div>
        </div>
        <footer
          className={classes.actions}
          style={{ justifyContent: "flex-end" }}
        >
          <button className="btn btn-primary" onClick={props.onConfirm}>
            Okay
          </button>
        </footer>
      </div>
    </div>
  );
};

export const VideoModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheVideoModal
          title={props.title}
          message={props.message}
          textMessage={props.textMessage}
          onConfirm={props.onConfirm}
        />,
        document.getElementById("modal")
      )}
    </Fragment>
  );
};
