// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\r\n  margin: 0;\r\n  font-family: 'Montserrat', sans-serif !important;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  background-color: #edf1f5 !important;\r\n}\r\n\r\ncode {\r\n  font-family: 'Montserrat', sans-serif !important;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAKA;EACE,SAAS;EACT,gDAAgD;EAChD,mCAAmC;EACnC,kCAAkC;EAClC,oCAAoC;AACtC;;AAEA;EACE,gDAAgD;AAClD","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap');\r\n\r\n\r\n\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: 'Montserrat', sans-serif !important;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  background-color: #edf1f5 !important;\r\n}\r\n\r\ncode {\r\n  font-family: 'Montserrat', sans-serif !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
