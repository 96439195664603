export const Table = (props) => {

    const thekeys = Object.keys(props.data[0])
    const thevalues = Object.values(props.data[0])

    return (<div className="table-responsive sites-table bg-white text-nowrap">
        <table className="table table-striped">
            <thead>
                <tr>
                    {thekeys.map((keys, index) => {
                        return <th key={index} scope="col" className="text-uppercase">{keys}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {(props.data.map((data, index) => {
                    return (<tr scope='row' key={index}>
                        {thekeys.map((datain, idx) => {
                            return <td key={idx}>{data[datain]}</td>
                        })}
                    </tr>)
                }))}
            </tbody>
        </table>
    </div>)
}