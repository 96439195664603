import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TopNav } from '../../Components/TopNav/TopNav';
import { Sidebar } from '../Layout/Sidebar/Sidebar';
import { allDashboardData, Sites } from '../Redux/AllSites';
import axios from 'axios';
import { backendURL } from '../../Components/backend';
import { allUserData } from '../Redux/AllUsers';
import { Spinner } from '../../Components/Spinner/Loader';

export const Dashboard = () => {
  const navbarShow = useSelector((state) => state.navbarToggle.show);
  const dispatch = useDispatch();

  const [loading, isloading] = useState(false);
  const [loadingSet, isloadingSet] = useState(false);

  const [spinner, setSpinner] = useState(false);

  const getToken = localStorage.getItem('token');
  const error = useSelector((state) => state.getAllsites.error);
  const [AllData, setAllData] = useState({});
  const [data, setData] = useState(null);

  useEffect(() => {
    const allDashboardData = async () => {
      setSpinner(true);
      try {
        const paidRes = await axios({
          method: 'GET',
          url: `${backendURL}/api/getAllPaidScripts`,
          data: {},
          headers: {
            authorization: `Bearer ${getToken}`,
          },
        });
        const unPaidRes = await axios({
          method: 'GET',
          url: `${backendURL}/api/getAllUnpaidScripts`,
          data: {},
          headers: {
            authorization: `Bearer ${getToken}`,
          },
        });
        const unPaidData = unPaidRes.data.filter(
          (data) => data.feature === 'ALL_FEATURES'
        );
        const paidData = paidRes.data.filter(
          (data) => data.feature === 'ALL_FEATURES'
        );
        setData({
          paid: paidData.length,
          unpaid: unPaidData.length,
        });
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
      }
    };
    allDashboardData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      isloading(true);
      await axios({
        method: 'GET',
        url: `${backendURL}/api/getAllData`,
        data: {},
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
          isloading(false);
          setAllData(res.data);
        })
        .catch((e) => {
          isloading(false);
        });
    };
    fetchData();
    dispatch(allUserData(getToken));
  }, []);

  const fixx = (domainName, feature) => {
    const RunTheTask2 = async () => {
      isloadingSet(true);

      const resp = await axios({
        method: 'POST',
        url: `${backendURL}/api/getAllScripts`,
        data: {},
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
          isloadingSet(false);

          alert("הנתונים נשמרו")
        })
        .catch((e) => {
          isloadingSet(false);

          alert("בעיה בשמירה. יש לנסות שוב")
        });
    };
    RunTheTask2();
  };

  return (
    <>
      <div className='dashboard-wrapper'>
        <div
          className={navbarShow ? 'sidebar px-md-3' : 'sidebar show px-md-3'}
        >
          <Sidebar> </Sidebar>
        </div>
        <div className='right-content'>
          <div className='content'>
            <TopNav />
            {/* =============== Inner Section Start ============= */}

            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                <h1 className='h3 mb-0 text-gray-800'> Admin Dashboard </h1>
              </div>
              {spinner && <Spinner color='#2285b6' />}
              <button className='btn-secondary btn'  onClick={() => fixx()}>
                {loadingSet ?  <Spinner color='#2285b6' />:' תיקון אוטומטי '}
                              </button>
                               
              <div className='row'>
                <div className='col-xl-3 col-md-6 mb-4'>
                  <div className='card  shadow h-100 py-2'>
                    <div className='card-body'>
                      <div className='row no-gutters align-items-center'>
                        <div className='col mr-2'>
                          <div className='text-xs font-weight-bold text-secondary text-uppercase mb-1'>
                            Users
                          </div>
                          <div className='h5 mb-0  text-gray-800'>
                            {AllData.TotalUsers ? AllData.TotalUsers : 0}
                          </div>
                        </div>
                        <div className='col-auto'>
                          <i className='fas fa-calendar fa-2x text-secondary'></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-md-6 mb-4'>
                  <div className='card shadow h-100 py-2'>
                    <div className='card-body'>
                      <div className='row no-gutters align-items-center'>
                        <div className='col mr-2'>
                          <div className='text-xs font-weight-bold text-info text-uppercase mb-1'>
                            Scripts
                          </div>
                          <div className='row no-gutters align-items-center'>
                            <div className='col-auto'>
                              <div className='h5 mb-0 mr-3 text-gray-800'>
                                {data?.paid || data?.unpaid
                                  ? data?.paid + data?.unpaid
                                  : 0}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-auto'>
                          <i className='fas fa-clipboard-list fa-2x text-info'></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-xl-1  mb-4"></div> */}
                <div className='col-xl-3 col-md-6 mb-4'>
                  <div className='card  shadow h-100 py-2'>
                    <div className='card-body'>
                      <div className='row no-gutters align-items-center'>
                        <div className='col mr-2'>
                          <div className='text-xs font-weight-bold text-success text-uppercase mb-1'>
                            Paid
                          </div>
                          <div className='h5 mb-0  text-gray-800'>
                            {data?.paid ? data?.paid : 0}
                          </div>
                        </div>
                        <div className='col-auto'>
                          <i className='fas fa-dollar-sign fa-2x text-success'></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-xl-3 col-md-6 mb-4'>
                  <div className='card  shadow h-100 py-2'>
                    <div className='card-body'>
                      <div className='row no-gutters align-items-center'>
                        <div className='col mr-2'>
                          <div className='text-xs font-weight-bold text-warning text-uppercase mb-1'>
                            UnPaid
                          </div>
                          <div className='h5 mb-0  text-gray-800'>
                            {data?.unpaid ? data?.unpaid : 0}
                          </div>
                        </div>
                        <div className='col-auto'>
                          <i className='fas fa-dollar-sign fa-2x text-warning'></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-1  mb-4"></div> */}
              </div>
            </div>

            {/* =============== Inner Section End ============= */}
            <div className='col-xl-3 col-md-6 mb-4'>
              <div className='card  shadow h-100 py-2'>
                <div className='card-body'>
                  <div className='row no-gutters align-items-center'>
                    <div className='col mr-2'>
                      <div className='text-xs font-weight-bold text-success text-uppercase mb-1'>
                        Active Scripts
                      </div>
                      <div className='h5 mb-0  text-gray-800'>
                        {data?.paid ? data?.paid : 0}
                      </div>
                    </div>
                    <div className='col-auto'>
                      <i className='fas fa-clipboard-list fa-2x text-success'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
