import axios from 'axios';
import { backendURL } from '../../Components/backend';
import { getAllUsers } from './UserAuth';

export const allUserData = (token) => async (dispatch) => {
  await axios({
    method: 'GET',
    url: `${backendURL}/api/getAllUsers`,
    data: {},
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      localStorage.setItem('all-users', JSON.stringify(res.data.user));
      dispatch(getAllUsers(res.data.user));
    })
    .catch((e) => {
    });
};
