import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../../Components/Spinner/Loader";
import { TopNav } from "../../../Components/TopNav/TopNav";
import { Sidebar } from "../../Layout/Sidebar/Sidebar";
import axios from "axios";
import { Modal } from "../../../Components/Modal/Modal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "react-loading";
import { backendURL } from "../../../Components/backend";
import SearchInput from "../../../Components/SearchInput/SearchInput";
import { VideoModal } from "../../../Components/Modal/VideoModal";

const CanceledSites = () => {
  let tempCounter = 1;
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const navbarShow = useSelector((state) => state.navbarToggle.show);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [actionLoading, setActionLoading] = useState(false);

  const [script, setScript] = useState();
  const [ShowModal, setShowModal] = useState(false);
  const getToken = localStorage.getItem("token");
  const user = localStorage.getItem("email");
  const [searchText, setSearchText] = useState(null);
  const [filterUsers, setFilterUsers] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const [notifyLoadingModal, setNotifyLoadingModal] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const [noVideos, setNoVideos] = useState("");

  const navigate = useNavigate();
  const fetchData = async () => {
    setIsLoading(true);
    await axios({
      method: "GET",
      url: `${backendURL}/api/getAllCancelledScripts`,
      data: {},
      headers: {
        authorization: `Bearer ${getToken}`,
      },
    })
      .then((res) => {
        console.log("data");
        setAllUsers(res.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const ShowScript = (uemail, domainName, feature) => {
    setModalLoading(true);
    const RunTheTask = async () => {
      const resp = await axios({
        method: "POST",
        url: `${backendURL}/api/getScript`,
        data: { email: uemail, domainName: domainName, feature: feature },
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
          setScript(res.data.script);
          setShowModal(true);
          setModalLoading(false);
        })
        .catch((e) => {
          setModalLoading(false);
          setScript(e.response.data.error);
          setShowModal(true);
        });
    };
    RunTheTask();
  };

  const handleConfirm = () => {
    setShowModal(false);
    setNotFound(false);
    setError(false);
    setNotifyModal(false);
    setVideoDetails({});
    setNoVideos("");
  };

  // Filtered All_Features Data
  const data = allUsers?.filter((data) => data.feature === "ALL_FEATURES");

  // Filter Search Users
  useEffect(() => {
    const filter = data?.filter((user) =>
      user.domain.toLowerCase().includes(searchText)
    );
    if (searchText) {
      if (filter.length <= 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
    }
    setFilterUsers(filter);
  }, [searchText]);

  const deActivateScriptInPyapal = (selectedDomain, feature, userEmail) => {
    const RunTheTask = async () => {
      setActionLoading(true);
      const resp = await axios({
        method: "POST",
        url: `${backendURL}/api/deActivateFreeScript`,
        data: {
          email: userEmail,
          domain: selectedDomain,
          feature: feature,
          paymentMethod: "PAYPAL",
        },
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
          setActionLoading(false);

          fetchData();
        })
        .catch((e) => {
          setActionLoading(false);
          setError(true);
          setErrorMessage(e.response.data.error);
        });
    };
    RunTheTask();
  };

  // Get Videos Details
  const ShowVideoDetails = (userEmail, domainName) => {
    setNotifyLoadingModal(true);
    const RunTheTask = async () => {
      const resp = await axios({
        method: "GET",
        url: `${backendURL}/api/getVideosSiteStorage/${userEmail}/${domainName}`,
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
          if (res.data) {
            setVideoDetails(res.data);
          }
          setNotifyModal(true);
          setNotifyLoadingModal(false);
        })
        .catch((e) => {
          setNotifyLoadingModal(false);
          setNoVideos(e.response.data.error);
          setNotifyModal(true);
        });
    };
    RunTheTask();
  };
  return (
    <div className="wrapper">
      <div className="dashboard-wrapper">
        <div
          className={navbarShow ? "sidebar px-md-3" : "sidebar show px-md-3"}
        >
          <Sidebar> </Sidebar>
        </div>
        <div className="right-content">
          <div className="content">
            <TopNav />
            {/* =============== Inner Section Start ============= */}
            {actionLoading ? <Spinner color="#2285b6" /> : ""}
            {modalLoading ? (
              <Modal
                title="Script"
                message={script}
                script={<Spinner color="#2285b6" />}
                onConfirm={handleConfirm}
              />
            ) : (
              ShowModal && (
                <Modal
                  title="Script"
                  message={script}
                  script={script}
                  onConfirm={handleConfirm}
                />
              )
            )}
            {notFound && (
              <Modal
                title="Error"
                message={""}
                error={"No Record Found"}
                onConfirm={handleConfirm}
              />
            )}
            {notifyLoadingModal ? <Spinner color="#2285b6" /> : ""}
            {notifyModal && (
              <VideoModal
                title="Video Details"
                message={videoDetails}
                textMessage={noVideos}
                onConfirm={handleConfirm}
              />
            )}
            <div className="container-fluid ">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Canceled Scripts</h1>
              </div>
              <SearchInput
                setSearchText={setSearchText}
                placeholder={"Search by domain name"}
              />
              {/*Error Message */}
              {error && (
                <Modal
                  title="Error"
                  message={""}
                  error={errorMessage}
                  onConfirm={handleConfirm}
                />
              )}
              <div className="table-responsive sites-table bg-white">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">PhoneNo</th>
                      <th scope="col">Feature</th>
                      <th scope="col">Domain</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && (
                      <tr>
                        <td>
                          <Spinner color="#2285b6" />
                        </td>
                      </tr>
                    )}
                    {data.length == 0 && !isLoading ? (
                      <tr>
                        <td>
                          <p>No Record Found</p>
                        </td>
                      </tr>
                    ) : filterUsers.length > 0 ? (
                      filterUsers.map((data, idx) => {
                        return (
                          <tr scope="row" key={idx}>
                            <th scope="row">{tempCounter++}</th>
                            <td>{data.Name}</td>
                            <td>{data.Email}</td>
                            <td>{data.PhoneNo}</td>

                            <td>{data.feature}</td>
                            <td>{data.domain}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  ShowScript(
                                    data.Email,
                                    data.domain,
                                    data.feature
                                  )
                                }
                              >
                                GetScript
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      data.map((data, idx) => {
                        return (
                          <tr scope="row" key={idx}>
                            <th scope="row">{tempCounter++}</th>
                            <td>{data.Name}</td>
                            <td>{data.Email}</td>
                            <td>{data.PhoneNo}</td>

                            <td>{data.feature}</td>
                            <td>{data.domain}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  ShowScript(
                                    data.Email,
                                    data.domain,
                                    data.feature
                                  )
                                }
                              >
                                GetScript
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn  btn-secondary"
                                onClick={() =>
                                  ShowVideoDetails(data.Email, data.domain)
                                }
                              >
                                VideoDetails
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* =============== Inner Section End ============= */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanceledSites;
