import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../Components/Spinner/Loader';
import { TopNav } from '../../../Components/TopNav/TopNav';
import { Sidebar } from '../../Layout/Sidebar/Sidebar';
import axios from 'axios';
import { Modal } from '../../../Components/Modal/Modal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from 'react-loading';
import { backendURL } from '../../../Components/backend';
import SearchInput from '../../../Components/SearchInput/SearchInput';
import { NotifyModal } from '../../../Components/Modal/NotifyModal';

export const UnPaid = () => {
  let tempCounter = 1;
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navbarShow = useSelector((state) => state.navbarToggle.show);
  const [searchText, setSearchText] = useState(null);
  const [filterUsers, setFilterUsers] = useState([]);
  const [notFound, setNotFound] = useState(false);

  const [message,setMessage] = useState('')
  const [modalShow,setModalShow] = useState(false)
  const [script, setScript] = useState();
  const [ShowModal, setShowModal] = useState(false);
  const getToken = localStorage.getItem('token');
  const user = localStorage.getItem('email');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await axios({
        method: 'GET',
        url: `${backendURL}/api/getAllUnpaidScripts`,
        data: {},
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
          setAllUsers(res.data);
          setIsLoading(false);
        })
        .catch((e) => {
          
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  const sendReminder = (Uemail, Udomain) => {
    setIsLoading(true);
    const RunTheTask = async () => {
      const resp = await axios({
        method: 'POST',
        url: `${backendURL}/api/sendReminder`,
        data: { email: Uemail, domain: Udomain },
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
          setModalShow(true)
          setMessage(res.data.message)
          setIsLoading(false);
        })
        .catch((e) => {
          setModalShow(true)
          setMessage('There was an error sending message, Try Again Later!')
          setIsLoading(false);
        });
    };
    RunTheTask();
  };

  const handleConfirm = () => {
    setModalShow(false)
    setShowModal(false);
    setNotFound(false);
  };

  const UpgradeScript = (domainName) => {
    localStorage.setItem('domain', domainName);
    navigate('/paymentplans');
  };

  // Filtered All_Features Data
  const data = allUsers?.filter((data) => data.feature === 'ALL_FEATURES');

  // Filter Search Users
  useEffect(() => {
    const filter = data.filter((user) =>
      user.domain.toLowerCase().includes(searchText)
    );
    if (searchText) {
      if (filter.length <= 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
    }
    setFilterUsers(filter);
  }, [searchText]);
  return (
    <div className='wrapper'>
      <div className='dashboard-wrapper'>
        <div
          className={navbarShow ? 'sidebar px-md-3' : 'sidebar show px-md-3'}
        >
          <Sidebar> </Sidebar>
        </div>
        <div className='right-content'>
          <div className='content'>
            <TopNav />
            {/* =============== Inner Section Start ============= */}

            {modalShow && <NotifyModal title={'Response'} message={message} onConfirm={handleConfirm}></NotifyModal>}

            {ShowModal && (
              <Modal
                title='Script'
                message={script}
                onConfirm={handleConfirm}
              />
            )}
            {notFound && (
              <Modal
                title='Error'
                message={''}
                error={'No Record Found'}
                onConfirm={handleConfirm}
              />
            )}
            <div className='container-fluid '>
              <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                <h1 className='h3 mb-0 text-gray-800'>Unpaid Scripts</h1>
              </div>
              <SearchInput
                setSearchText={setSearchText}
                placeholder={'Search by domain name'}
              />
              <div className='table-responsive sites-table bg-white'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th scope='col'>#</th>
                      <th scope='col'>Name</th>
                      <th scope='col'>Email</th>
                      <th scope='col'>Domain</th>
                      <th scope='col'>PhoneNo</th>
                    </tr>
                  </thead>
                  <tbody>
                  {isLoading &&  <tr><td><Spinner color='#2285b6' /></td></tr>}
                    {data.length == 0 && !isLoading ? (
                    <tr><td><p>No Record Found</p></td></tr>  
                    ) : filterUsers.length > 0 ? (
                      filterUsers.map((data, idx) => {
                        return (
                          <tr scope='row' key={idx}>
                            <th scope='row'>{tempCounter++}</th>
                            <td>{data.Name}</td>
                            <td>{data.Email}</td>
                            <td>{data.domain}</td>
                            <td>{data.PhoneNo}</td>
                            <td>
                              <button
                                className='btn btn-secondary'
                                onClick={() =>
                                  sendReminder(data.Email, data.domain)
                                }
                              >
                                Send Reminder
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      data.map((data, idx) => {
                        return (
                          <tr scope='row' key={idx}>
                            <th scope='row'>{tempCounter++}</th>
                            <td>{data.Name}</td>
                            <td>{data.Email}</td>
                            <td>{data.domain}</td>
                            <td>{data.PhoneNo}</td>
                            <td>
                              <button
                                className='btn btn-secondary'
                                onClick={() =>
                                  sendReminder(data.Email, data.domain)
                                }
                              >
                                Send Reminder
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* =============== Inner Section End ============= */}
          </div>
        </div>
      </div>
    </div>
  );
};
