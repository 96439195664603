import React from 'react'
import ReactLoading from 'react-loading'
import { ReactReduxContext } from 'react-redux'


export const Spinner = (props)=> {

    return(
    <>
    <span className='spinner' style={{display:'inline-block'}}>
       <ReactLoading type='spinningBubbles' color={props.color} height={25} width={25} ></ReactLoading>
    </span>
    </>)

}