import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TopNav } from '../../../Components/TopNav/TopNav';
import { Sidebar } from '../../Layout/Sidebar/Sidebar';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import './add.css'
import { useState } from 'react';
import { Spinner } from '../../../Components/Spinner/Loader';
import { backendURL } from '../../../Components/backend';
import { allUserData } from '../../Redux/AllUsers';
import { Modal } from '../../../Components/Modal/Modal';
import Select from 'react-select';
// import icon1 from '../../../assets/images/icons/icon1.png';

import icon2 from '../../../assets/images/icons/icon2.png';
import icon3 from '../../../assets/images/icons/icon3.png';
import icon4 from '../../../assets/images/icons/icon4.png';
import icon5 from '../../../assets/images/icons/icon5.png';
import icon6 from '../../../assets/images/icons/icon6.png';
import icon7 from '../../../assets/images/icons/icon7.png';
import icon8 from '../../../assets/images/icons/icon8.png';
import icon9 from '../../../assets/images/icons/icon9.png';
import icon10 from '../../../assets/images/icons/icon10.png';


const AddNewSite = () => {
  const dispatch = useDispatch();
  const navbarShow = useSelector((state) => state.navbarToggle.show);
  const allUsers = JSON.parse(localStorage.getItem('all-users'));

  const [userEmail, setUserEmail] = useState({
    value: 'Select User',
    label: 'Select User',
  });
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [isLoading, setLoading] = useState(false);
  const [feature, setFeature] = useState('Select Feature');
  const [script, setScript] = useState(null);
  const [ShowModal, setShowModal] = useState(false);

  const [anyError, setanyErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dis, setDi] = useState('icon');

  const [icon, setIcon] = useState('2');
  const [color, setColor] = useState('#3ed1bd ');
  const [top, setTop] = useState('bottom');
  const [right, setRight] = useState('right');
  const [language, setLanguage] = useState('iw');
  const [border, setBorder] = useState('50');
  const [size, setSize] = useState('middle');
  const [colorIcon, setColorIcon] = useState('#0d1a2f');

  const [isChecked, setIsChecked] = useState(false)
  const [isDefault, setIsCheckedDefault] = useState(true)

  const [nameStatement, setNameStatement] = useState();
  const [phoneStatement, setPhoneStatement] = useState();
  const [emailStatement, setEmailStatement] = useState();
  const [dataStatement, setDataStatement] = useState();

  const checkHandler = () => {
    setIsChecked(!isChecked)
  }
  const checkHandlerD = () => {
    setIsCheckedDefault(true)
    console.log("isDefault1", isDefault)
    if (isDefault) {
      console.log("tes")
      setIcon('2');
      setColor('#3ed1bd');
      setTop('bottom');
      setRight('right')
      setLanguage('iw');
      setBorder('50')
      setSize('middle');
      setColorIcon('#0d1a2f');
    }

  }
  const options = allUsers.map((data) => {
    return {
      value: data.Email,
      label: data.Email,
    };
  });

  useEffect(() => {
    dispatch(allUserData(token));
  }, []);

  const formik = useFormik({
    initialValues: {
      domainName: '',
    },
    validationSchema: Yup.object({
      domainName: Yup.string().required('Required'),
    }),
    onSubmit: (values, { resetForm }) => {
      if (
        values.domainName.includes('/') ||
        values.domainName.includes('http')
      ) {
        if (values.domainName === '') {
          setErrorMessage(true);
          setErrorMessage('Please Enter Domain Name !');
        } else if (values.domainName.includes('http')) {
          setanyErrorMessage(true);
          setErrorMessage(
            'Please remove the protocols http/https. Type domain like "www.example.com"'
          );
        } else {
          setanyErrorMessage(true);
          setErrorMessage('Please remove all / backslashes  !');
        }
      } else if (!values.domainName.includes('www')) {
        setanyErrorMessage(true);
        setErrorMessage('Incomplete Domain Name !');
      } else {
        const editDomain = values.domainName.split('.');
        editDomain.shift();
        const finalDomain = editDomain.join('.');

        if (feature === 'Select Feature' || userEmail.value == 'Select User') {
          setanyErrorMessage(true);
          setErrorMessage('Please Select Both Feature And User');
        } else {
          setLoading(true);
          axios({
            method: 'POST',
            url: `${backendURL}/api/addAdminSite`,
            data: {
              email: userEmail.value,
              domain: finalDomain,
              language: language,
              platform: 'WordPress',
              feature: feature,
              colors: color,
              sizeRight: right,
              img: icon,
              sizeTop: top,
              border: border,
              sizeIcon: size,
              colorIcon: colorIcon,
              nameS: nameStatement,
              phoneS: phoneStatement,
              emailS: emailStatement,
              dataS: dataStatement
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              setLoading(false);
              setScript(res.data);
              setShowModal(true);
              resetForm({ values: '' });
              setFeature('Select Feature');
              setUserEmail('Select User');
            })
            .catch((e) => {
              setLoading(false);
              setanyErrorMessage(true);
              setErrorMessage(e.message) ||
                setErrorMessage(e.response.data.error);
            });
        }
      }
    },
  });
  const handleConfirm = () => {
    setShowModal(false);
  };

  return (
    <div className='wrapper'>
      <div className='dashboard-wrapper'>
        <div
          className={navbarShow ? 'sidebar px-md-3' : 'sidebar show px-md-3'}
        >
          <Sidebar> </Sidebar>
        </div>
        <div className='right-content'>
          <div className='content'>
            <TopNav />
            {ShowModal && (
              <Modal
                title='Script'
                message={script}
                script={script}
                onConfirm={handleConfirm}
              />
            )}
            {/* =============== Inner Section Start ============= */}
            <div className='container-fluid'>
              <div className='row align-items-start'>
                <div className='col-md-12 m-auto'>
                  <div className=' align-items-center justify-content-between mb-4'>
                    <h1 className=' mt-3 text-gray-800 text-right'>
                      <strong>
                        הוספת אתר חדש
                      </strong>
                    </h1>
                  </div>

                  {/* //========= Error Message ======== */}

                  <div className='col-md-12 m-auto'>
                    <div className='row ' dir='rtl'>
                      <div
                        className='px-md-3 m-auto text-left my-5'

                      >
                        {/* //===== Error Message Block  ====*/}

                        {/* //========= Error Message ======== */}

                        <div
                          className='alert alert-warning alert-dismissible fade show'
                          role='alert'
                          style={{ display: anyError ? 'block' : 'none' }}
                        >
                          <strong>{errorMessage}</strong>
                          <button
                            type='button'
                            className='close'
                            onClick={() => setanyErrorMessage(!anyError)}
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>

                        {/* //===== Sign Up Form   ====*/}
                        <form onSubmit={formik.handleSubmit}>
                          <div className=' row text-right' >
                            <div className='form-group mx-auto col-lg-4 col-md-4 col-sm-12'>
                              <label>בחר משתמש</label>
                              {/* <select
                                className='custom-form-control'
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                              >
                                <option value={'Select Feature'}>
                                  Select User
                                </option>
                                {allUsers?.map((data, index) => (
                                  <option value={data.Email} key={index}>
                                    {data.Email}
                                  </option>
                                ))}
                              </select> */}
                              <Select
                                className='form-control'
                                value={userEmail}
                                onChange={setUserEmail}
                                options={options} />

                            </div>
                            <div className='form-group mx-auto col-lg-4 col-md-4 col-sm-12'>
                              <label>בחר שם דומיין</label>
                              <input
                                type='text'
                                name='domainName'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.domainName}
                                className='form-control'
                                placeholder='www.example.com'
                              ></input>
                              {formik.touched.domainName &&
                                formik.errors.domainName ? (
                                <p className='text-danger mt-1'>
                                  {formik.errors.domainName}
                                </p>
                              ) : null}
                            </div>
                            <div className='form-group mx-auto col-lg-4 col-md-4 col-sm-12'>
                              <label>בחר תוכנית</label>
                              <select
                                className='form-control'
                                value={feature}
                                onChange={(e) => setFeature(e.target.value)}
                              >
                                <option value={'Select Feature'}>
                                  Select Feature
                                </option>
                                {/* <option value={'PLUGIN_ANALYTICS_COMBO'}>
                                  Plugin Analytics Combo
                                </option>
                                <option value={'ALT_TEXT'}>Alt Text</option> */}
                                <option value={'ALL_FEATURES'}>
                                  All Features
                                </option>
                              </select>
                            </div>
                            <div className='col-8 style-plugin'>
                              <div className='col-12 text-center m-auto my-3'>

                                <label>
                                  <input className='check_icon' type='radio' name="ch" value={"icon"} onChange={(e) => setDi(e.target.value)} defaultChecked></input>
                                  <span className='style_dis'>
                                    עיצוב הסמל
                                  </span>
                                </label>
                                <label>
                                  <input className='check_icon' type='radio' name="ch" value={"menu"} onChange={(e) => setDi(e.target.value)} ></input>
                                  <span className='style_dis'>
                                    עיצוב התפריט
                                  </span>
                                </label>
                                <hr></hr>
                              </div>
                              {dis === 'icon' ?
                                <span>


                                  <div className=' style-plugin'>
                                    {/* icon */}
                                    <div >

                                      <h3>בחר/י איקון </h3>
                                      {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-down-lines-filled" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M9 8l-.117 .007a1 1 0 0 0 -.883 .993v1.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-1.999a1 1 0 0 0 -1 -1h-6z" stroke-width="0" fill="currentColor" />
                                      <path d="M15 2a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                                      <path d="M15 5a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                                    </svg>*/}
                                      <br></br>

                                      <label>
                                        <input className='check_icon' type='radio' name="icon" value={2} onChange={(e) => setIcon(e.target.value)}></input>
                                        <img className='img-fluid icons' src={icon2} alt='icon2'></img>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="icon" value={3} onChange={(e) => setIcon(e.target.value)}></input>
                                        <img className='img-fluid icons' src={icon3} alt='icon3'></img>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="icon" value={4} onChange={(e) => setIcon(e.target.value)}></input>
                                        <img className='img-fluid icons' src={icon4} alt='icon4'></img>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="icon" value={5} onChange={(e) => setIcon(e.target.value)} ></input>
                                        <img className='img-fluid icons' src={icon5} alt='icon5'></img>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="icon" value={6} onChange={(e) => setIcon(e.target.value)}></input>
                                        <img className='img-fluid icons' src={icon6} alt='icon6'></img>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="icon" value={7} onChange={(e) => setIcon(e.target.value)}></input>
                                        <img className='img-fluid icons' src={icon7} alt='icon7'></img>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="icon" value={8} onChange={(e) => setIcon(e.target.value)}></input>
                                        <img className='img-fluid icons' src={icon8} alt='icon8'></img>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="icon" value={9} onChange={(e) => setIcon(e.target.value)}></input>
                                        <img className='img-fluid icons' src={icon9} alt='icon9'></img>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="icon" value={10} onChange={(e) => setIcon(e.target.value)}></input>
                                        <img className='img-fluid icons' src={icon10} alt='icon10'></img>
                                      </label>
                                    </div>
                                  </div>
                                  <div className=' style-plugin'>
                                    <hr></hr>
                                    {/* color */}
                                    <div >

                                      <h3>בחר/י צבע </h3>
                                      {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-down-lines-filled" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M9 8l-.117 .007a1 1 0 0 0 -.883 .993v1.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-1.999a1 1 0 0 0 -1 -1h-6z" stroke-width="0" fill="currentColor" />
                                      <path d="M15 2a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                                      <path d="M15 5a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                                    </svg> */}
                                      <br></br>
                                      <label>
                                        <input type='text' name="color" className='more_option' onChange={(e) => setColorIcon(e.target.value)} placeholder='#111'></input>
                                      </label>
                                      <br></br>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#000"} onChange={(e) => setColorIcon(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#000' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#0d1a2f"} onChange={(e) => setColorIcon(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#0d1a2f' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#B11010"} onChange={(e) => setColorIcon(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#B11010' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#AA0E51"} onChange={(e) => setColorIcon(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#AA0E51' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#A40E75"} onChange={(e) => setColorIcon(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#A40E75' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#8B0EA4"} onChange={(e) => setColorIcon(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#8B0EA4' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#3B0EA4"} onChange={(e) => setColorIcon(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#3B0EA4' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#0E5B95"} onChange={(e) => setColorIcon(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#0E5B95' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#05663C"} onChange={(e) => setColorIcon(e.target.value)} ></input>
                                        <div className='colors' style={{ background: '#05663C' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#3C5F07"} onChange={(e) => setColorIcon(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#3C5F07' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#5D5656"} onChange={(e) => setColorIcon(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#5D5656' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#7E4815"} onChange={(e) => setColorIcon(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#7E4815' }}></div>
                                      </label>

                                      <p> הצבעים הבאים מתואמים לרמת נגישות AAA</p>
                                    </div>
                                  </div>

                                  <div className=' form-group   style-plugin'>
                                    <hr></hr>
                                    <h3> צורת התוסף
                                      {/* <input type='range' min="0" max="50" value={border} onChange={(e) => setBorder(e.target.value)}></input> */}
                                    </h3>
                                    <div>
                                      {/* <h4 className='mb-3'> הגדר את גודל התוסף</h4> */}
                                      <label>
                                        <input className='check_icon' type='radio' name="size" value={"0% 50% 50% 0"} onChange={(e) => setBorder(e.target.value)} ></input>
                                        <div className='style_border style_border_1'></div>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="size" value={"0"} onChange={(e) => setBorder(e.target.value)} ></input>
                                        <div className='style_border style_border_2'></div>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="size" value={"50"} onChange={(e) => setBorder(e.target.value)} ></input>
                                        <div className='style_border style_border_3'></div>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="size" value={"30"} onChange={(e) => setBorder(e.target.value)} ></input>
                                        <div className='style_border style_border_4'></div>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="size" value={"50% 0% 0 50"} onChange={(e) => setBorder(e.target.value)} ></input>
                                        <div className='style_border style_border_5'></div>
                                      </label>
                                    </div>
                                    <div>

                                      <label>
                                        <input className='check_icon' type='radio' name="size" value={"big"} onChange={(e) => setSize(e.target.value)} ></input>
                                        <span className='style_size'>
                                          סמל גדול
                                        </span>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="size" value={"middle"} onChange={(e) => setSize(e.target.value)} defaultChecked></input>
                                        <span className='style_size'>
                                          סמל ממוצע
                                        </span>
                                      </label>
                                      <label>
                                        <input className='check_icon' type='radio' name="size" value={"small"} onChange={(e) => setSize(e.target.value)}></input>
                                        <span className='style_size'>
                                          סמל קטן
                                        </span>
                                      </label>
                                    </div>

                                  </div>
                                  <div className=' style-plugin mt-3'>
                                    {/* location */}
                                    <div >

                                      <h3>בחר/י את מיקום התוסף באתר</h3>
                                      {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-down-lines-filled" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M9 8l-.117 .007a1 1 0 0 0 -.883 .993v1.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-1.999a1 1 0 0 0 -1 -1h-6z" stroke-width="0" fill="currentColor" />
                                      <path d="M15 2a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                                      <path d="M15 5a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                                    </svg> */}
                                      <br></br>
                                      <div>
                                        <span className='location'>
                                          {top}

                                          {/* <input type='text' name="top" className='more_option' onChange={(e) => setTop(e.target.value)} placeholder='#111'></input> */}

                                          <div>
                                            <input type='range' min="0" max="91" value={top} onChange={(e) => setTop(e.target.value)}></input>
                                            <br></br>
                                            <label>
                                              <input className='radio-style1 check_top' id="outline" type='radio' name="top" value={"top"} onChange={(e) => setTop(e.target.value)} defaultChecked></input>
                                              <span className='location_span'>
                                                <i class="fa fa-arrow-up mx-1"></i>
                                                למעלה
                                              </span>
                                            </label>
                                            <label>
                                              <input className='radio-style1 check_top' id="outline" type='radio' name="top" value={"bottom"} onChange={(e) => setTop(e.target.value)}></input>
                                              <span className='location_span'>
                                                <i class="fa fa-arrow-down mx-1"></i>
                                                למטה
                                              </span>
                                            </label>
                                          </div>
                                        </span>
                                        <span className='location'>
                                          <label>
                                            <input className='radio-style1 check_top' id="outline" type='radio' name="right" value={"right"} onChange={(e) => setRight(e.target.value)} defaultChecked></input>
                                            <span className='location_span'>
                                              <i class="fa fa-arrow-right mx-1"></i>
                                              ימין
                                            </span>
                                          </label>
                                          <label>
                                            <input className='radio-style1 check_top' id="outline" type='radio' name="right" value={"left"} onChange={(e) => setRight(e.target.value)}></input>
                                            <span className='location_span'>
                                              <i class="fa fa-arrow-left mx-1"></i>
                                              שמאל
                                            </span>
                                          </label>
                                        </span>
                                      </div>
                                    </div>
                                  </div>


                                </span>
                                :
                                <div>
                                  <div className=' style-plugin my-3'>
                                    {/* color */}
                                    <div >

                                      <h3>בחר/י צבע </h3>
                                      <p> הצבעים הבאים מתואמים לרמת נגישות AAA</p>
                                      {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-down-lines-filled" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M9 8l-.117 .007a1 1 0 0 0 -.883 .993v1.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-1.999a1 1 0 0 0 -1 -1h-6z" stroke-width="0" fill="currentColor" />
                                      <path d="M15 2a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                                      <path d="M15 5a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                                    </svg> 
                                    <br></br>*/}
                                      <label>
                                        <input type='text' name="color" className='more_option' onChange={(e) => setColor(e.target.value)} placeholder='#111'></input>
                                      </label>
                                      <br></br>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#FFA50A"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#FFA50A' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#FFDD1A"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#FFDD1A' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#FFED29"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#FFED29' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#29FF90"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#29FF90' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#37E178"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#37E178' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#2DCD4D"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#2DCD4D' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#3ed1bd"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#3ed1bd' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#00DBD8"} onChange={(e) => setColor(e.target.value)} ></input>
                                        <div className='colors' style={{ background: '#00DBD8' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#66B0FF"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#66B0FF' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#85D6FF"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#85D6FF' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#00FFFB"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#00FFFB' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#F1A8FF"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#F1A8FF' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#F976F5"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#F976F5' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#FF8099"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#FF8099' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#dcb5a4"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#dcb5a4' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#E0E0E0"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#E0E0E0' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#ededed"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#ededed' }}></div>
                                      </label>
                                      <label>
                                        <input className='check_color' type='radio' name="color" value={"#d0e4ed"} onChange={(e) => setColor(e.target.value)}></input>
                                        <div className='colors' style={{ background: '#d0e4ed' }}></div>
                                      </label>

                                    </div>
                                  </div>
                                  <div className=' style-plugin'>
                                    {/* language */}
                                    <div>
                                      <hr></hr>
                                      <h3>בחר/י את שפת התוסף</h3>
                                      {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-down-lines-filled" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M9 8l-.117 .007a1 1 0 0 0 -.883 .993v1.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-1.999a1 1 0 0 0 -1 -1h-6z" stroke-width="0" fill="currentColor" />
                                      <path d="M15 2a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                                      <path d="M15 5a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                                    </svg> */}
                                      <br></br>
                                      <label className='spee'>
                                        <input className='radio-style check_language' id="outline" type='radio' name="language" value={"iw"} onChange={(e) => setLanguage(e.target.value)} defaultChecked></input>
                                        <span className='location_span'>
                                          <img src='https://plugin.access4u.co.il/img/flag/i.png' className='img-fluid img_language'></img>
                                          {/* <br></br> */}
                                          עברית
                                        </span>
                                      </label>
                                      <label className='spee'>
                                        <input className='radio-style check_language' id="outline" type='radio' name="language" value={"en"} onChange={(e) => setLanguage(e.target.value)}  ></input>
                                        <span className='location_span'>
                                          <img src='https://plugin.access4u.co.il/img/flag/e.png' className='img-fluid img_language'></img>
                                          {/* <br></br> */}
                                          English
                                        </span>
                                      </label>
                                      <label className='spee'>
                                        <input className='radio-style check_language' id="outline" type='radio' name="language" value={"fr"} onChange={(e) => setLanguage(e.target.value)}></input>
                                        <span className='location_span'>
                                          <img src='https://plugin.access4u.co.il/img/flag/f.png' className='img-fluid img_language'></img>
                                          {/* <br></br> */}
                                          Français
                                        </span>
                                      </label>
                                      <label className='spee'>
                                        <input className='radio-style check_language' id="outline" type='radio' name="language" value={"ar"} onChange={(e) => setLanguage(e.target.value)}></input>
                                        <span className='location_span'>
                                          <img src='https://plugin.access4u.co.il/img/flag/a.png' className='img-fluid img_language'></img>
                                          {/* <br></br> */}
                                          العربية
                                        </span>
                                      </label>
                                      <label className='spee'>
                                        <input className='radio-style check_language' id="outline" type='radio' name="language" value={"ru"} onChange={(e) => setLanguage(e.target.value)}></input>
                                        <span className='location_span'>
                                          <img src='https://plugin.access4u.co.il/img/flag/r.png' className='img-fluid img_language'></img>
                                          {/* <br></br> */}
                                          Русский
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className='style-plugin d-sm-flex align-items-center justify-content-center '>
                                    <div class="checkbox-wrapper-37">
                                      <input type="checkbox" name="checkbox" id="terms-checkbox-37" checked={isChecked} onChange={checkHandler} />
                                      <label for="terms-checkbox-37" class="terms-label  mt-4 ml-3">

                                        <svg
                                          class="checkbox-svg"
                                          viewBox="0 0 200 200"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <mask id="path-1-inside-1_476_5-37" fill="white">
                                            <rect width="200" height="200" />
                                          </mask>
                                          <rect
                                            width="200"
                                            height="200"
                                            class="checkbox-box"
                                            stroke-width="40"
                                            mask="url(#path-1-inside-1_476_5-37)"
                                          />
                                          <path
                                            class="checkbox-tick"
                                            d="M52 111.018L76.9867 136L149 64"
                                            stroke-width="15"
                                          />
                                        </svg>
                                        <span class="label-text mx-3"> האם את/ה מעוניין בהצהרת נגישות מותאמת?</span>

                                      </label>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                            <div className='col-4  form-group text-center style-plugin'>
                              <hr></hr>
                              <h3> איך התוסף יראה באתר</h3>
                              <div class="checkbox-wrapper-37">
                                {icon != '2' || color != '#3ed1bd' || top != 'bottom' || right != 'right' || language != 'iw' || border != '50' || size != 'middle' || colorIcon != '#0d1a2f'
                                  ?
                                  <div>
                                    <input type="checkbox" name="checkbox" id="terms-checkbox-38" checked={!isDefault} onChange={checkHandlerD} />
                                    <label for="terms-checkbox-38" class="terms-label  mt-4 ">

                                      <svg
                                        class="checkbox-svg"
                                        viewBox="0 0 200 200"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <mask id="path-1-inside-1_476_5-37" fill="white">
                                          <rect width="200" height="200" />
                                        </mask>
                                        <rect
                                          width="200"
                                          height="200"
                                          class="checkbox-box"
                                          stroke-width="40"
                                          mask="url(#path-1-inside-1_476_5-37)"
                                        />
                                        <path
                                          class="checkbox-tick"
                                          d="M52 111.018L76.9867 136L149 64"
                                          stroke-width="15"
                                        />
                                      </svg>
                                      <span class="label-text mx-3"> בחר עיצוב ברירת מחדל- מומלץ</span>

                                    </label>
                                  </div>
                                  : <div>
                                    <input type="checkbox" name="checkbox" id="terms-checkbox-38" checked={isDefault} onChange={checkHandlerD} />
                                    <label for="terms-checkbox-38" class="terms-label  mt-4 ">

                                      <svg
                                        class="checkbox-svg"
                                        viewBox="0 0 200 200"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <mask id="path-1-inside-1_476_5-37" fill="white">
                                          <rect width="200" height="200" />
                                        </mask>
                                        <rect
                                          width="200"
                                          height="200"
                                          class="checkbox-box"
                                          stroke-width="40"
                                          mask="url(#path-1-inside-1_476_5-37)"
                                        />
                                        <path
                                          class="checkbox-tick"
                                          d="M52 111.018L76.9867 136L149 64"
                                          stroke-width="15"
                                        />
                                      </svg>
                                      <span class="label-text mx-3"> בחר עיצוב ברירת מחדל- מומלץ</span>
                                    </label>
                                  </div>
                                }
                              </div>

                              {dis === 'icon' ?
                                <div className={`demo-plugin demo-${size}`} style={{ borderRadius: `${border}` + '%', background: `${colorIcon}` }}>
                                  {icon == '' ? '' : <img className='demo-icon' src=
                                    {`https://plugin.access4u.co.il/img/icons_w/icon` + `${icon}` + `.png`}
                                  ></img>}
                                </div>
                                :
                                <div className='menu'>
                                  <div className='nav-M' style={{ background: `${color}` }}>
                                    <div className='row m-auto'>
                                      <div className='col-6 my-2 p-0 text-right'>
                                        <i class="material-icons mx-2 ">close</i>
                                        <i class="material-icons mx-2">swap_horiz</i>
                                        <i class="material-icons mx-2">color_lens</i>
                                      </div>
                                      <div className='col-6 my-2 p-0 text-left'>

                                        <i class="material-icons fix_ur_web fix_ur_web_icon_arror">expand_more</i>

                                      </div>
                                    </div>
                                  </div>
                                  <div className='body'></div>
                                  <div className='footer' style={{ background: `${color}` }}>
                                    <div className='row'>
                                      <div className='col-md-4 p-1'><p> בטל נגישות</p></div>
                                      <div className='col-md-4 p-1'><p>  כפתור מצוקה</p></div>
                                      <div className='col-md-4 p-1'><p>  הצהרת נגישות</p></div>

                                    </div>
                                  </div>
                                  <p> תפריט זה פותח על ידי עושים שוויון</p>
                                </div>
                              }
                            </div>

                          </div>


                          {isChecked ?
                            <div className='col-md-12  text-center  style-plugin'>
                              <hr></hr>
                              <h3>הצהרת נגישות</h3>
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-down-lines-filled" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M9 8l-.117 .007a1 1 0 0 0 -.883 .993v1.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-1.999a1 1 0 0 0 -1 -1h-6z" stroke-width="0" fill="currentColor" />
                                <path d="M15 2a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                                <path d="M15 5a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z" stroke-width="0" fill="currentColor" />
                              </svg>
                              <br></br>
                              <div className='row my-3'>
                                <div className='col-md-6 text-center'>

                                  <div>
                                    <input type='text' name="name" className='statement_input' onChange={(e) => setNameStatement(e.target.value)} value={nameStatement} placeholder='שם רכז הנגישות כפי שיופיע באתר' ></input>
                                    <br></br>
                                    <input type='phone' name="phone" className='statement_input' onChange={(e) => setPhoneStatement(e.target.value)} value={phoneStatement} placeholder='טלפון רכז הנגישות כפי שיופיע באתר' ></input>
                                    <br></br>
                                    <input type='email' name="email" className='statement_input' onChange={(e) => setEmailStatement(e.target.value)} value={emailStatement} placeholder='מייל רכז הנגישות כפי שיופיע באתר' ></input>
                                    <br></br>
                                    <textarea name='ddd' className='statement_textarea' rows={5} placeholder='הסבר על נגישות העסק' onChange={(e) => setDataStatement(e.target.value)} value={dataStatement} ></textarea>
                                    {/* <input type='text' name="data" className='statement_input' onChange={(e) => setDataStatement(e.target.value)} value={dataStatement} placeholder='הסבר על נגישות העסק' required></input> */}
                                  </div>

                                </div>
                                <div className='col-md-6 text-center mx-auto'>


                                  <div className='statement'>
                                    {/*<h3>הצהרת נגישות</h3>
     <h4>
      מָבוֹא</h4>
    <p>

      בְּיָמֵינוּ, כְּשֶׁהָאִינְטֶרְנֵט מְשַׁמֵּשׁ לִדְבָרִים רַבִּים בְּחַיֵּי הַיּוֹם יוֹם,
      אֵצֶל רֹב בְּנֵי הָאָדָם, חָלָה חוֹבָה לְהַנְגִישׁ כָּל אֲתָר וְאֲתָר עַל מְנַת לְאַפְשֵׁר
      חֲוָיַת גְּלִישָׁה נוֹחָה.<br></br>
      יֶשְׁנָם אֲנָשִׁים אֲשֶׁר שִׁמּוּשׁ בַּאֲתָרִים נַעֲשָׂה עֲבוּרָם קָשֶׁה עַד בִּלְתִּי
      אֶפְשָׁרִי, עֵקֶב מֻגְבָּלוּיוֹת שׁוֹנוֹת.<br></br>
      בְּעִקְבוֹת כָּךְ, אָנוּ עוֹשִׂים כָּל מַאֲמָץ אֶפְשָׁרִי עַל מְנַת לְקַדֵּם וּלְהַנְגִישׁ
      אֶת הָאֲתָרִים לְכֻלָּם.<br></br>
      הַחָזוֹן שֶׁלָּנוּ הוּא שֶׁכָּל אָדָם בַּאֲשֶׁר הוּא יוּכַל לִבְחֹר מַהִי הַדֶּרֶךְ
      הַקַּלָּה בְּיוֹתֵר עֲבוּרוֹ לִגְלֹשׁ בָּאִינְטֶרְנֵט, וְאָנוּ נוּכַל לְסַיֵּעַ
      בְּיָדוֹ.<br></br>
      הַדֶּרֶךְ שֶׁלָּנוּ הִיא לִזְכֹּר, לְכֹל אָדָם מַגִּיעַ שִׁוְויוֹן. וְאָנוּ נַעֲשָׂה הַכֹּל
      עַל מְנַת לְהַגִּיעַ לְכָךְ.<br></br>
    </p>
    <h4>
      כֵּיצַד עוֹבֶדֶת הַהַנְגָּשָׁה בָּאֲתָר?
    </h4>
    <p>
      בָּאֲתָר הֻטְמַע תּוֹסַף נְגִישׁוּת שֶׁל חֶבְרַת עוֹשִׂים שִׁוְויוֹן. עַל מְנַת לְאַפְשֵׁר
      לַמִּשְׁתַּמֵּשׁ לִבְחֹר בְּאֶפְשָׁרוּיוֹת צְפִיָּה וּגְלִישָׁה נוֹחוּת יוֹתֵר.<br></br>
      בְּעֵת לְחִיצָה עַל סַמָּן הַתֹּסֶף (סַמָּן יָרֹק בְּתַחְתִּית הַדַּף מִיָּמִין), יִפָּתַח
      תַּפְרִיט הַנְּגִישׁוּת.<br></br>
    </p>
    <h4>
      נִתְקַלְתֶּם בִּבְעָיָה?
    </h4>
    <p>
      בְּמִדָּה וְלֹא מַצָאתֶם אֶת הַתֹּכֶן שֶׁחִפַּשְׂתֶּם בָּאֲתָר אוֹ שֶׁהִנְּכֶם מְעֻנְיָנִים בְּעֶזְרָה מִנָּצִיג שֵׁרוּת, תּוּכְלוּ לְהַקִּישׁ f4 וְתִפָּתַח חַלּוֹנִית לְהַשְׁאָרַת פְּרָטִים. הַשְׁאִירוּ שָׁם וְטֵלֵפוֹן וּנְצִיג מִטַּעֲמֵנוּ יִצֹּר אִתְּכֶם קֶשֶׁר.<br></br>
      בְּנוֹסָף, נִתָּן לִלְחֹץ עַל "כַּפְתּוֹר מְצוּקָה" בַּתּוֹסָף ( נִמְצָא בְּתַחְתִּית הַתֹּסֶף) עַל מְנַת לְחַיֵּג אוֹ לִשְׁלֹחַ הוֹדָעָה מְהִירָה. בְּעֵת שִׁמּוּשׁ בַּמַּחְשֵׁב יֵשׁ לִלְחֹץ עַל הַכַּפְתּוֹר הָאָדֹם כְּדֵי לְהוֹרִיד הִתְחַבְּרוּת מֵרָחוֹק.
    </p>
    <h4>
      מָה בְּתַפְרִיט הַנְּגִישׁוּת?
    </h4>
    <ul>
      <li> נִוּוּט מָהִיר בֵּין הַדַּפִּים הַמֶּרְכָּזִים בָּאֲתָר.</li>
      <li>הַתְאָמַת גֹּדֶל הַגּוֹפָן בָּאֲתָר.</li>
      <li>שִׁנּוּיֵי רֶוַח הַתָּוִים בָּאֲתָר.</li>
      <li>שִׁנּוּיֵי רֶוַח בֵּין הַשּׁוּרוֹת בָּאֲתָר.</li>
      <li>הַגְדָּלַת תְּצוּגַת הָאֲתָר.</li>
      <li>הַתְאָמַת מִקּוּם הַטֶּקְסְטִים בָּאֲתָר (ימִין/שְׂמֹאל/מֶרְכָּז).</li>
      <li>שִׁנּוּי צִבְעֵי הַכּוֹתָרוֹת/ הָרְקָעִים/ הַטֶּקְסְטִים בָּאֲתָר.</li>
      <li>צֶבַע סַמַּן עַכְבָּר - שִׁנּוּי סַמָּן הָעַכְבָּר ל: גָּדוֹל וְשָׁחֹר/ גָּדוֹל וְלָבָן.
      </li>
      <li>גּוֹפָן קָרִיא- שִׁנּוּי הַפוֹנְט לְפוֹנְט קָרִיא יוֹתֵר.</li>
      <li>הַדְגָּשַׁת כּוֹתָרוֹת - הַדְגָּשַׁת כֹּל הַכּוֹתָרוֹת בָּאֲתָר.</li>
      <li>הַדְגָּשַׁת קִשּׁוּרִים - הַדְגָּשַׁת כָּל הַקִּשּׁוּרִים בָּאֲתָר.</li>
      <li>הַגְדָּלַת טֶקְסְטִים - הַצָּגַת הַטֶּקְסְטִים בָּאֲתָר בְּחַלּוֹנִית צָפָה בְּעֵת
        מַעֲבָר.</li>
      <li>תּוֹמֵךְ קְרִיאָה - הוֹסָפַת סַמָּן וִירְטוּאָלִי עַל מְנַת לַעֲקֹב אַחֲרֵי מִקּוּם
        הַמִּשְׁתַּמֵּשׁ בָּאֲתָר. </li>
      <li>תּוֹמֵךְ מִקּוּד - הַדְגָּשַׁת אֵזוֹר מְסֻיָּם בָּאֲתָר בְּעֵת מַעֲבָר.</li>
      <li>נִגּוּדִיּוּת כֵּהֶה - שִׁנּוּיֵי צִבְעֵי הָאֲתָר לַבָּהִיר עַל גַּבֵּי רֶקַע כֵּהֶה.
      </li>
      <li>נִגּוּדִיּוּת בְּהִירָה - שִׁנּוּי צִבְעֵי הָאֲתָר לַכֵּהִים עַל גַּבֵּי רֶקַע בָּהִיר.
      </li>
      <li>נִגּוּדִיּוּת גְּבוֹהָה - שִׁנּוּי צִבְעֵי הָאֲתָר לְצְבָעִים מְנֻגָּדִים.</li>
      <li>רְוָיָה גְּבוֹהָה - הַגְבָּרַת עֹצְמַת הַצְּבָעִים בָּאֲתָר.</li>
      <li>רְוָיָה נְמוּכָה - הַפְחָתַת עֹצְמַת הַצְּבָעִים בָּאֲתָר.</li>
      <li>מוֹנוֹכְרוֹם - שִׁנּוּי צִבְעֵי הָאֲתָר לְשָׁחֹר לָבָן.</li>
      <li>הַשְׁתֵּק צְלִילִים - הַשְׁתָּקָה מִיָּדִית לַכֹּל הֲוִדְּאוֹ אוֹ הָאוֹדְיוֹ בָּאֲתָר.
      </li>
      <li>הַסְתֵּר תְּמוּנוֹת - הַסְתָּרַת כֹּל הַתְּמוּנוֹת בָּאֲתָר.</li>
      <li>עֲצִירַת אָנִימַצְיוֹת - הַשְׁבָּתַת הִבְהוּבִים וְאֵלֵמֶנְטִים נָעִים עַל הַמָּסָךְ.
      </li>
      <li>הַדְגָּשַׁת רַחַף - הַדְגָּשַׁת אֵלֵמֵנְט סְפֵּצִיפִי בְּעֵת מַעֲבָר.</li>
      <li>הַדְגָּשַׁת לְחִיצָה - הַדְגָּשַׁת אֵלֵמֵנְט סְפֵּצִיפִי בְּעֵת לְחִיצָה.</li>
      <li>מַצַּב קְרִיאָה - תְּצוּגַת הָאֲתָר לְלֹא עִצּוּב וּלְלֹא תְּמוּנוֹת.</li>
      <li>נִוּוּט מִקְלֶדֶת - נִוּוּט בְּעֶזְרַת מַקַּשֵׁי הַמִּקְלֶדֶת בֵּין הַקִּשּׁוּרִים/
        הַכּוֹתָרוֹת/ הַכַּפְתּוֹרִים/ הַטְּפָסִים/ הַתַּפְרִיטִים/ הַתְּמוּנוֹת בָּאֲתָר.</li>
      <li>קוֹרֵא מָסָךְ - הַקְרָאַת טֶקְסְטִים בָּאֲתָר עַל יְדֵי לְחִיצָה עַל הַטֵּקְסְט
        הַמְּבֻקָּשׁ.</li>
      <li>מִיקְרוֹפוֹן - בִּצּוּעַ פְּקֻדּוֹת קוֹלִיּוֹת</li>
    </ul>
    <h4>
      שִׂימוּ לֵב
    </h4>
    <p>
      אָנוּ עוֹשִׂים מַאֲמַצִּים רַבִּים עַל מְנַת לְאַפְשֵׁר לְכֹל קְהַל הַמִּשְׁתַּמְּשִׁים
      בָּאֲתָר חֲוָיַת גְּלִישָׁה נוֹחָה וּנְגִישָׁה.<br></br>
      לַמְרוֹת זֹאת, יִתָּכֵן וּרְכִיבִים מְסֻיָּמִים בַּאֲתָר לֹא הֻנְגְּשׁוּ לְגַמְרֵי.<br></br>
      אִם מְצָאָתַם בְּעָיַת נְגִישׁוּת כָּלְשֶׁהִי, מֻזְמָנִים לְעַדְכֵּן אוֹתָנוּ
      וְאָנוּ נַעֲשָׂה הַכֹּל עַל מְנַת שֶׁבְּעָיָה זוֹ תִּפָּתֵר.<br></br>
    </p> */}
                                    <h4>
                                      פִּרְטֵי רַכַּז הַנְּגִישׁוּת
                                    </h4>
                                    <p>
                                      שֵׁם: {nameStatement}<br></br>
                                      טֵלֵפוֹן : {phoneStatement}<br></br>
                                      דוא”ל : {emailStatement}<br></br>
                                    </p>
                                    <h4>
                                      נגישות העסק:
                                    </h4>
                                    <p>
                                      {dataStatement}
                                    </p>
                                  </div>

                                  {/* <img src={sample} alt='Sample' className='img-fluid' /> */}

                                </div>
                              </div>

                            </div>
                            : ""}
                          <div className='form-group my-2 mx-auto text-center col-lg-4 col-md-10 col-sm-12'>
                            <button
                              className='btn btn-form btn-primary '
                              type='submit'
                              style={{ width: '100%' }}
                            >
                              {' '}
                              {isLoading ? <Spinner /> : `Add Site`}{' '}
                            </button>
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* =============== Inner Section End ============= */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewSite;
