import { Link } from 'react-router-dom';
import './Sidebar.css';
import logo from '../../../assets/images/logo-small.jpg';
import { useEffect } from 'react';

export const Sidebar = () => {
  return (
    <>
      <div className='align-self-start text-center mt-md-4 mt-5'>
        <img
          alt='logo-img'
          style={{ width: '90%' }}
          src={logo}
          className='sidebar-logo'
        ></img>
        <h5 className='mt-2 text-white'>
          Welcome<br></br> Admin
        </h5>
      </div>
      <hr></hr>
      <div className='mt-3'>
        <ul className='rounded sidebar-list-wrapper'>
          <li className='sidebar-item nav-items'>
            <div className='link-wrapper'>
              <div className='icon-wrapper'>
                <i className='far fa-credit-card '></i>
              </div>
              <div className='text-wrapper'>
                <Link to='/dashboard'>
                  {' '}
                  <span className='text-white hide-text nav-text'>
                    Dashboard{' '}
                  </span>
                </Link>{' '}
              </div>
            </div>
          </li>
          <li className='sidebar-item nav-items'>
            <div className='link-wrapper'>
              <div className='icon-wrapper'>
                <i className='fas fa-user  '></i>
              </div>
              <div className='text-wrapper'>
                <Link to='/allusers'>
                  {' '}
                  <span className='text-white hide-text nav-text'>Users </span>
                </Link>{' '}
              </div>
            </div>
          </li>
          {/* <li className='sidebar-item nav-items'>
            <div className='link-wrapper'>
              <div className='icon-wrapper'>
                <i className='fas fa-code '></i>
              </div>
              <div className='text-wrapper'>
                <Link to='/allscripts'>
                  {' '}
                  <span className='text-white hide-text nav-text'>
                    Scripts{' '}
                  </span>
                </Link>{' '}
              </div>
            </div>
          </li> */}
          {/*Scripts Dropdown */}
          <li
            className='sidebar-item nav-items'
            data-toggle='collapse'
            data-target='#trafic'
          >
            <div className='link-wrapper'>
              <div className='icon-wrapper'>
                <i className='fas fa-code '></i>
              </div>
              <div className='text-wrapper'>
                <span className='hide-text nav-text'>Scripts </span>
              </div>
            </div>
            <div
              className='sidebar-drop-down collapse collapse-data text-white bg-white'
              id='trafic'
            >
              <p className='inner-links-wrapper '>
                <Link to='/paid'>
                  <span className='nav-links text-dark'>Paid</span>
                </Link>
              </p>
              <p className='inner-links-wrapper '>
                <Link to='/unpaid'>
                  <span className='nav-links text-dark'>UnPaid </span>
                </Link>
              </p>
              <p className='inner-links-wrapper '>
                <Link to='/paused'>
                  <span className='nav-links text-dark '>Paused</span>
                </Link>
              </p>
              <p className='inner-links-wrapper '>
                <Link to='/canceled'>
                  <span className='nav-links text-dark '>Canceled</span>
                </Link>
              </p>
              <p className='inner-links-wrapper mb-0'>
                <Link to='/freeSites'>
                  <span className='nav-links text-dark '>Free</span>
                </Link>
              </p>
            </div>
          </li>
          {/*Scripts Dropdown */}
          {/* <li className='sidebar-item nav-items'>
            <div className='link-wrapper'>
              <div className='icon-wrapper'>
                <i class='far fa-money-bill-alt'></i>
              </div>
              <div className='text-wrapper'>
                <Link to='/paid'>
                  <span className='text-white hide-text nav-text'>Paid </span>
                </Link>{' '}
              </div>
            </div>
          </li>
          <li className='sidebar-item nav-items'>
            <div className='link-wrapper'>
              <div className='icon-wrapper'>
                <i class='fas fa-money-check-alt'></i>
              </div>
              <div className='text-wrapper'>
                <Link to='/unpaid'>
                  {' '}
                  <span className='text-white hide-text nav-text'>UnPaid </span>
                </Link>{' '}
              </div>
            </div>
          </li> */}
          {/* <li className='sidebar-item nav-items'>
            <div className='link-wrapper'>
              <div className='icon-wrapper'>
                <i class='fas fa-money-check-alt'></i>
              </div>
              <div className='text-wrapper'>
                <Link to='/freeSites'>
                  {' '}
                  <span className='text-white hide-text nav-text'>
                    Free Sites{' '}
                  </span>
                </Link>{' '}
              </div>
            </div>
          </li> */}
          <li className='sidebar-item nav-items'>
            <div className='link-wrapper'>
              <div className='icon-wrapper'>
                <i className='fas fa-user '></i>
              </div>
              <div className='text-wrapper'>
                <Link to='/addnewsite'>
                  {' '}
                  <span className='text-white hide-text nav-text'>
                    Add New Site
                  </span>
                </Link>{' '}
              </div>
            </div>
          </li>
          <li className='sidebar-item nav-items'>
            <div className='link-wrapper'>
              <div className='icon-wrapper'>
                <i className='fas fa-code '></i>
              </div>
              <div className='text-wrapper'>
                <Link to='/addnewuser'>
                  {' '}
                  <span className='text-white hide-text nav-text'>
                    Add New User
                  </span>
                </Link>{' '}
              </div>
            </div>
          </li>
          <li className='sidebar-item nav-items'>
            <div className='link-wrapper'>
              <div className='icon-wrapper'>
                <i className='fas fa-phone-alt'></i>
              </div>
              <div className='text-wrapper'>
                <Link to='/contactus'>
                  <span className='text-white hide-text nav-text'>
                    Message All Clients{' '}
                  </span>
                </Link>{' '}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};
