import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../Components/Spinner/Loader';
import { TopNav } from '../../../Components/TopNav/TopNav';
import { Sidebar } from '../../Layout/Sidebar/Sidebar';
import axios from 'axios';
import { Modal } from '../../../Components/Modal/Modal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from 'react-loading';
import { Unpaid } from '../../Redux/AllSites';
import { backendURL } from '../../../Components/backend';

export const AllScripts = () => {
  let tempCounter = 1;
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const navbarShow = useSelector((state) => state.navbarToggle.show);

  const [script, setScript] = useState();
  const [error, setError] = useState(null);
  const [ShowModal, setShowModal] = useState(false);
  const getToken = localStorage.getItem('token');
  const user = localStorage.getItem('email');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await axios({
        method: 'GET',
        url: `${backendURL}/api/getAllUnpaidScripts`,
        data: {},
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((result) => {
          const unpaid = result.data.map((data) => ({
            ...data,
            status: 'unpaid',
          }));
          setAllUsers([...unpaid]);
          axios({
            method: 'GET',
            url: `${backendURL}/api/getAllPaidScripts`,
            data: {},
            headers: {
              authorization: `Bearer ${getToken}`,
            },
          }).then((resp) => {
            setIsLoading(true);
            const paid = resp.data.map((data) => ({ ...data, status: 'paid' }));
            setAllUsers([...unpaid, ...paid]);

            axios({
              method: 'GET',
              url: `${backendURL}/api/getAllFreeScripts`,
              data: {},
              headers: {
                authorization: `Bearer ${getToken}`,
              },
            }).then((resp) => {
              setIsLoading(true);
              const free = resp.data.map((data) => ({
                ...data,
                status: 'free',
              }));
              setAllUsers([...unpaid, ...paid, ...free]);
              setIsLoading(false);
            });
          });
        })
        .catch((e) => {
        });
    };

    fetchData();
  }, []);

  const ShowScript = (domainName, feature, userEmail) => {
    setModalLoading(true);
    const RunTheTask = async () => {
      const resp = await axios({
        method: 'POST',
        url: `${backendURL}/api/getScript`,
        data: { email: userEmail, domainName: domainName, feature: feature },
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
          setScript(res.data.script);
          setShowModal(true);
          setModalLoading(false);
        })
        .catch((e) => {
          setModalLoading(false);
          setScript(e.response.data.error);
          setShowModal(true);
        });
    };
    RunTheTask();
  };

  const deActivateScriptInPyapal = (selectedDomain, feature, userEmail) => {
    const RunTheTask = async () => {
      const resp = await axios({
        method: 'POST',
        url: `${backendURL}/api/deActivateScript`,
        data: {
          email: userEmail,
          domain: selectedDomain,
          feature: feature,
          paymentMethod: 'PAYPAL',
        },
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
        })
        .catch((e) => {
        });
    };
    RunTheTask();
  };

  const ActivateScriptInPyapal = (selectedDomain, feature, userEmail) => {
    const RunTheTask = async () => {
      const resp = await axios({
        method: 'POST',
        url: `${backendURL}/api/activateScript`,
        data: {
          email: userEmail,
          domain: selectedDomain,
          feature: feature,
          paymentMethod: 'PAYPAL',
        },
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
        })
        .catch((e) => {
        });
    };
    RunTheTask();
  };

  const handleConfirm = () => {
    setShowModal(false);
  };

  return (
    <div className='wrapper'>
      <div className='dashboard-wrapper'>
        <div
          className={navbarShow ? 'sidebar px-md-3' : 'sidebar show px-md-3'}
        >
          <Sidebar> </Sidebar>
        </div>
        <div className='right-content'>
          <div className='content'>
            <TopNav />
            {/* =============== Inner Section Start ============= */}
            {modalLoading ? (
              <Modal
                title='Script'
                message={script}
                script={<Spinner color='#2285b6' />}
                onConfirm={handleConfirm}
              />
            ) : (
              ShowModal && (
                <Modal
                  title='Script'
                  message={script}
                  script={script}
                  onConfirm={handleConfirm}
                />
              )
            )}
            <div className='container-fluid '>
              <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                <h1 className='h3 mb-0 text-gray-800'>All Scripts</h1>
              </div>

              <div className='table-responsive sites-table bg-white'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th scope='col'>#</th>
                      <th scope='col'>Name</th>
                      <th scope='col'>Email</th>
                      <th scope='col'>PhoneNo</th>
                      <th scope='col'>Domain</th>
                      <th scope='col'>Payment</th>
                      <th scope='col'>Status</th>
                      <th scope='col' colSpan='2' className='text-center'>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allUsers.length == 0 ? (
                      <Spinner color='#2285b6' />
                    ) : (
                      allUsers.map((data) => {
                        return (
                          <tr scope='row'>
                            <th scope='row'>{tempCounter++}</th>
                            <td>{data.Name}</td>
                            <td>{data.Email}</td>
                            <td>{data.PhoneNo}</td>
                            <td>{data.domain}</td>
                            <td>
                              {data.status == 'unpaid' ? (
                                <span className='text-danger'>UNPAID</span>
                              ) : (
                                <span
                                  className={`text-uppercase ${
                                    data.status == 'paid'
                                      ? 'text-success '
                                      : 'text-danger'
                                  }`}
                                >
                                  {data.status}
                                </span>
                              )}
                            </td>
                            <td>{data.isActive ? 'Active' : 'InActive'}</td>

                            <td>
                              <button
                                className='btn btn-success'
                                onClick={() =>
                                  ActivateScriptInPyapal(
                                    data.domain,
                                    data.feature,
                                    data.Email
                                  )
                                }
                                disabled={data.isActive ? true : false}
                              >
                                Activate
                              </button>
                            </td>

                            <td>
                              <button
                                className='btn btn-danger'
                                onClick={() =>
                                  deActivateScriptInPyapal(
                                    data.domain,
                                    data.feature,
                                    data.Email
                                  )
                                }
                                disabled={!data.isActive ? true : false}
                              >
                                DeActivate
                              </button>
                            </td>
                            <td>
                              <button
                                className='btn btn-primary'
                                onClick={() =>
                                  ShowScript(
                                    data.domain,
                                    data.feature,
                                    data.Email
                                  )
                                }
                              >
                                GetScript
                              </button>
                            </td>

                            {/* <td><button className="btn btn-secondary" >Send Reminder</button></td> */}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              {isLoading ? <Spinner color='#2285b6' /> : null}
            </div>

            {/* =============== Inner Section End ============= */}
          </div>
        </div>
      </div>
    </div>
  );
};
