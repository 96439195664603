import React, { useState } from 'react';

const SearchInput = ({ setSearchText, placeholder }) => {
  const [text, setText] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    if (text.trim() !== '') {
      setSearchText(text.toLowerCase());
      setText('');
    }
  };
  return (
    <div className='d-flex align-items-center w-100'>
      <form
        className='d-flex align-items-center mb-4 w-100 '
        onSubmit={handleSubmit}
      >
        <input
          type='text'
          placeholder={placeholder}
          className='form-control search-input'
          value={text}
          onChange={(e) => setText(e.target.value)}
        />

        <button className='btn btn-primary mx-2' type='submit'>
          Search
        </button>
        <button className='btn btn-secondary' onClick={() => setSearchText(null)}>
          Clear
        </button>
      </form>
    </div>
  );
};

export default SearchInput;
