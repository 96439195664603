import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../Components/Spinner/Loader';
import { TopNav } from '../../../Components/TopNav/TopNav';
import { Sidebar } from '../../Layout/Sidebar/Sidebar';
import axios from 'axios';
import { Modal } from '../../../Components/Modal/Modal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from 'react-loading';
import { backendURL } from '../../../Components/backend';
import SearchInput from '../../../Components/SearchInput/SearchInput';

export const AllUsers = () => {
  let tempCounter = 0;
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navbarShow = useSelector((state) => state.navbarToggle.show);
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [searchText, setSearchText] = useState(null);
  const [filterUsers, setFilterUsers] = useState([]);
  const [notFound, setNotFound] = useState(false);

  const [modalData, setModalData] = useState();
  const [ShowModal, setShowModal] = useState(false);
  const getToken = localStorage.getItem('token');
  const user = localStorage.getItem('email');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await axios({
        method: 'GET',
        url: `${backendURL}/api/getUsersData`,
        data: {},
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
          setIsLoading(false);
          setAllUsers(res.data);

        })
        .catch((e) => {
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  const handleConfirm = () => {
    setShowModal(false);
    setNotFound(false);
  };

  const showAllWebsites = async (email) => {
    setName(email);
    setIsLoading(true);
    const result = await axios({
      method: 'GET',
      url: `${backendURL}/api/getSites/${email} `,
      headers: {
        authorization: `Bearer ${getToken}`,
      },
    })
      .then((res) => {
        setName('');
        setIsLoading(false);
        let data = res?.data.filter((data) => data.feature === 'ALL_FEATURES');
        if(data.length){
          setModalData(data);
          setShowModal(true);
        }
      })
      .catch((error) => {
        setName('');
        setIsLoading(false);
      });
  };

  // Filter Search Users
  useEffect(() => {
    const filter = allUsers.filter((user) =>
      user.Name.toLowerCase().includes(searchText)
    );
    if (searchText) {
      if (filter.length <= 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
    }
    setFilterUsers(filter);
  }, [searchText]);

  return (
    <div className='wrapper'>
      <div className='dashboard-wrapper'>
        <div
          className={navbarShow ? 'sidebar px-md-3' : 'sidebar show px-md-3'}
        >
          <Sidebar> </Sidebar>
        </div>
        <div className='right-content'>
          <div className='content'>
            <TopNav />
            {/* =============== Inner Section Start ============= */}

            {ShowModal && (
              <Modal
                title='All Websites'
                message={modalData}
                onConfirm={handleConfirm}
              />
            )}
            {notFound && (
              <Modal
                title='Error'
                message={''}
                error={'No Record Found'}
                onConfirm={handleConfirm}
              />
            )}

            <div className='container-fluid '>
              <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                <h1 className='h3 mb-0 text-gray-800'>All Registered Users</h1>
              </div>
              <SearchInput
                setSearchText={setSearchText}
                placeholder={'Search users by name'}
              />
              <div className='table-responsive sites-table bg-white'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th scope='col'>#</th>
                      <th scope='col'>Name</th>
                      <th scope='col'>Email</th>
                      <th scope='col'>Websites</th>
                      <th scope='col'>Phone</th>
                      <th scope='col'>Paid</th>
                      <th scope='col'>UnPaid</th>
                      <th scope='col'>Free</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allUsers.length == 0 ? (
                     <tr><td><Spinner color='#2285b6' /></td></tr>
                    ) : filterUsers.length > 0 ? (
                      filterUsers.map((data, idx) => {
                        return (
                          <tr scope='row' key={idx}>
                            <th scope='row'>{tempCounter++}</th>
                            <td>{data.Name}</td>
                            <td>{data.Email}</td>
                            <td>{data.NoOfWebsites}</td>
                            <td>{data.PhoneNo}</td>
                            <td>{data.PaidWebsites}</td>
                            <td>{data.UnpaidWebsites}</td>
                            <td>{data.FreeWebsites}</td>
                            <td
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            > 
                              {name === data.Email ? (
                                <Spinner color='#2285b6'></Spinner>
                              ) : (
                                <button
                                  className='btn btn-primary'
                                  disabled={
                                    data.NoOfWebsites > 0 ? false : true
                                  }
                                  onClick={() => showAllWebsites(data.Email)}
                                >See all websites
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      allUsers && allUsers.map((data, idx) => {
                        return (
                          <tr scope='row' key={idx}>
                            <th scope='row'>{tempCounter++}</th>
                            <td>{data.Name}</td>
                            <td>{data.Email}</td>
                            <td>{data.NoOfWebsites}</td>
                            <td>{data.PhoneNo}</td>
                            <td>{data.PaidWebsites}</td>
                            <td>{data.UnpaidWebsites}</td>
                            <td>{data.FreeWebsites}</td>
                            <td
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {name === data.Email ? (
                                <Spinner color='#2285b6'></Spinner>
                              ) : (
                                <button
                                  style={{whiteSpace:'nowrap'}}
                                  className='btn btn-primary'
                                  disabled={
                                    data.NoOfWebsites > 0 ? false : true
                                  }
                                  onClick={() => showAllWebsites(data.Email)}
                                >
                                  {' '}
                                  See all websites
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* =============== Inner Section End ============= */}
          </div>
        </div>
      </div>
    </div>
  );
};
