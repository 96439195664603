import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import classes from './Modal.module.css';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useState } from 'react';
import { Spinner } from '../Spinner/Loader';
import axios from 'axios';

import icon11 from '../../assets/images/icons/icon2.png';
import icon3 from '../../assets/images/icons/icon3.png';
import icon4 from '../../assets/images/icons/icon4.png';
import icon5 from '../../assets/images/icons/icon5.png';
import icon6 from '../../assets/images/icons/icon6.png';
import icon7 from '../../assets/images/icons/icon7.png';
import icon8 from '../../assets/images/icons/icon8.png';
import icon9 from '../../assets/images/icons/icon9.png';
import icon10 from '../../assets/images/icons/icon10.png';
import { backendURL } from '../backend';


export const TheStyle = (props) => {
  console.log("props", props)
  const { style, LanguageD, Statement, domain, user } = props.message;
  console.log("style", style)
  const [icon, setIcon] = useState(style.img);
  const [color, setColor] = useState(style.colors);
  const [top, setTop] = useState(style.sizeTop);
  const [right, setRight] = useState(style.sizeRight);
  const [language, setLanguage] = useState(LanguageD);
  const [border, setBorder] = useState(style.border);
  const [size, setSize] = useState(style.sizeIcon);
  const [colorI, setColorIcon] = useState(style.colorIcon);

  let lSize;
  if (size == 'big') {
    lSize = "גדול"
  }
  else {
    if (size == 'middle') {
      lSize = "ממוצע"
    }
    else {
      lSize = 'קטן'
    }
  }
  let newlanguage;
  if (language == 'en') { newlanguage = 'English' }
  if (language == 'iw' || language == "English") { newlanguage = 'עיברית' }
  if (language == 'fr') { newlanguage = 'Français' }
  if (language == 'ar') { newlanguage = 'العربية' }
  if (language == 'ru') { newlanguage = 'Русский' }

  let newTop;
  if (top == 'top') { newTop = 'למעלה' }
  if (top == 'bottom') { newTop = 'למטה' }
  if (top >= 0 && top <= 91) { newTop = 'top:' + top }


  const [nameStatement, setNameStatement] = useState(Statement.nameS);
  const [phoneStatement, setPhoneStatement] = useState(Statement.phoneS);
  const [emailStatement, setEmailStatement] = useState(Statement.emailS);
  const [dataStatement, setDataStatement] = useState(Statement.dataS);

  console.log("size", size)
  const [isLoading, setLoading] = useState(false);
  const getToken = localStorage.getItem('token');
  const userEmail = localStorage.getItem('email');
  console.log("style", style)
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("aa");
    if (icon == style.img &&
      color == style.colors &&
      top == style.sizeTop &&
      right == style.sizeRight &&
      language == LanguageD &&
      border == style.border &&
      size == style.sizeIcon &&
      colorI == style.colorIcon &&
      nameStatement == Statement.nameS &&
      phoneStatement == Statement.phoneS &&
      emailStatement == Statement.emailS &&
      dataStatement == Statement.dataS) {
      alert("לא בוצעו שינויים")
    }
    else {
      setLoading(true);
      axios({
        method: 'POST',
        url: `${backendURL}/api/editscript`,
        data: {
          email: user,
          domain: domain,
          language: language,
          feature: 'ALL_FEATURES',
          colors: color,
          sizeRight: right,
          img: icon,
          sizeTop: top,
          border: border,
          sizeIcon: size,
          colorIcon: colorI,
          nameS: nameStatement,
          phoneS: phoneStatement,
          emailS: emailStatement,
          dataS: dataStatement
        },
        headers: {
          authorization: `Bearer ${getToken}`,
        },
      })
        .then((res) => {
          setLoading(false);
          console.log("res", res)
          props.onConfirm();

        })
        .catch((e) => {
          setLoading(false);
        });
    }

  }

  return (
    <div className='text-right'>
      <div className={classes.backdrop}>
        <div className={`${classes.modal} ${classes.card}`}>
          <header className={`row ${classes.header} ${classes.actions}`} dir='rtl'>
            <h2> {props.title} את {domain}</h2>
          </header>
          <form onSubmit={handleSubmit} className='w-100'>

            <div className={classes.content} dir="rtl">

              <div
                className={`${classes.innerScroll} rounded bg-white p-3 text-dark`}
              >
                <div className='container-full '>
                  <h3 className='bg-start text-center'> כך התוסף שלך נראה:</h3>
                  <div className='row m-auto form-group text-center style-plugin justify-content-center '>

                    <div className='col-md-2 m-auto'>
                      <div className={`demo-plugin demo-${size}`} style={{ borderRadius: `${border}` + `%`, background: `${colorI}` }}>
                        {icon == '' ? '' : <img className='demo-icon' src=
                          {`https://plugin.access4u.co.il/img/icons_w/icon` + `${icon}` + `.png`}
                        ></img>}
                      </div>
                    </div>
                    <div className='col-md-2 m-auto'>
                      <div className='menu2' style={{ background: `${color}` }}>
                        <p>צבע התפריט</p>
                      </div>
                    </div>
                    <div className='col-md-2 m-auto'>
                      <h4>{newlanguage}</h4>
                    </div>
                    <div className='col-md-2 m-auto'>
                      {right == 'right' ? <h4>ימין</h4> : <h4>שמאל</h4>}
                    </div>
                    <div className='col-md-2 m-auto'>

                      <h4>{newTop}</h4>
                      {/* {top == 'top' ? <h4>למעלה</h4> : <h4>למטה</h4>} */}
                    </div>
                    <div className='col-md-2 m-auto'>
                      <h4>{lSize}</h4>
                    </div>
                  </div>

                  <h3 className='bg-start text-center'> עריכה:</h3>
                  <div className='the-edit text-center'>
                    <h4 > גודל התוסף</h4>
                    <label>
                      <input className='check_icon' type='radio' name="size" value={"big"} onChange={(e) => setSize(e.target.value)}></input>
                      <span className='style_size'>
                        גדול
                      </span>
                    </label>
                    <label>
                      <input className='check_icon' type='radio' name="size" value={"middle"} onChange={(e) => setSize(e.target.value)}></input>
                      <span className='style_size'>
                        ממוצע
                      </span>
                    </label>
                    <label>
                      <input className='check_icon' type='radio' name="size" value={"small"} onChange={(e) => setSize(e.target.value)}></input>
                      <span className='style_size'>
                        קטן
                      </span>
                    </label>

                  </div>
                  <hr></hr>
                  <div className='the-edit text-center'>
                    <h4>גרור את הסמן לשינוי המסגרת</h4>
                    <div>
                      {/* <h4 className='mb-3'> הגדר את גודל התוסף</h4> */}
                      <label>
                        <input className='check_icon' type='radio' name="size" value={"0% 50% 50% 0"} onChange={(e) => setBorder(e.target.value)} ></input>
                        <div className='style_border style_border_1'></div>
                      </label>
                      <label>
                        <input className='check_icon' type='radio' name="size" value={"0"} onChange={(e) => setBorder(e.target.value)} ></input>
                        <div className='style_border style_border_2'></div>
                      </label>
                      <label>
                        <input className='check_icon' type='radio' name="size" value={"50"} onChange={(e) => setBorder(e.target.value)} ></input>
                        <div className='style_border style_border_3'></div>
                      </label>
                      <label>
                        <input className='check_icon' type='radio' name="size" value={"30"} onChange={(e) => setBorder(e.target.value)} ></input>
                        <div className='style_border style_border_4'></div>
                      </label>
                      <label>
                        <input className='check_icon' type='radio' name="size" value={"50% 0% 0 50"} onChange={(e) => setBorder(e.target.value)} ></input>
                        <div className='style_border style_border_5'></div>
                      </label>
                    </div>
                  </div>
                  <hr></hr>
                  <div className='the-edit m-auto text-center'>
                    <h4>איקון </h4>
                    <label>
                      <input className='check_icon' type='radio' name="icon" value={11} onChange={(e) => setIcon(e.target.value)}></input>
                      <img className='img-fluid icons' src={icon11} alt='icon11'></img>
                    </label>
                    <label>
                      <input className='check_icon' type='radio' name="icon" value={3} onChange={(e) => setIcon(e.target.value)}></input>
                      <img className='img-fluid icons' src={icon3} alt='icon3'></img>
                    </label>
                    <label>
                      <input className='check_icon' type='radio' name="icon" value={4} onChange={(e) => setIcon(e.target.value)}></input>
                      <img className='img-fluid icons' src={icon4} alt='icon4'></img>
                    </label>
                    <label>
                      <input className='check_icon' type='radio' name="icon" value={5} onChange={(e) => setIcon(e.target.value)} ></input>
                      <img className='img-fluid icons' src={icon5} alt='icon5'></img>
                    </label>
                    <label>
                      <input className='check_icon' type='radio' name="icon" value={6} onChange={(e) => setIcon(e.target.value)}></input>
                      <img className='img-fluid icons' src={icon6} alt='icon6'></img>
                    </label>
                    <label>
                      <input className='check_icon' type='radio' name="icon" value={7} onChange={(e) => setIcon(e.target.value)}></input>
                      <img className='img-fluid icons' src={icon7} alt='icon7'></img>
                    </label>
                    <label>
                      <input className='check_icon' type='radio' name="icon" value={8} onChange={(e) => setIcon(e.target.value)}></input>
                      <img className='img-fluid icons' src={icon8} alt='icon8'></img>
                    </label>
                    <label>
                      <input className='check_icon' type='radio' name="icon" value={9} onChange={(e) => setIcon(e.target.value)}></input>
                      <img className='img-fluid icons' src={icon9} alt='icon9'></img>
                    </label>
                    <label>
                      <input className='check_icon' type='radio' name="icon" value={10} onChange={(e) => setIcon(e.target.value)}></input>
                      <img className='img-fluid icons' src={icon10} alt='icon10'></img>
                    </label>
                  </div>
                  <hr></hr>
                  <div className='the-edit m-auto text-center'>
                    <h4>צבע התפריט</h4>
                    <br></br>
                    <label>
                      <input type='text' name="color" className='more_option' onChange={(e) => setColor(e.target.value)} placeholder='טקסט חופשי'></input>
                    </label>
                    <br></br>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#FFA50A"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#FFA50A' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#FFDD1A"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#FFDD1A' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#FFED29"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#FFED29' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#29FF90"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#29FF90' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#37E178"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#37E178' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#2DCD4D"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#2DCD4D' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#3ed1bd"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#3ed1bd' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#00DBD8"} onChange={(e) => setColor(e.target.value)} ></input>
                      <div className='colors' style={{ background: '#00DBD8' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#66B0FF"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#66B0FF' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#85D6FF"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#85D6FF' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#00FFFB"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#00FFFB' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#F1A8FF"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#F1A8FF' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#F976F5"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#F976F5' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#FF8099"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#FF8099' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#dcb5a4"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#dcb5a4' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#E0E0E0"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#E0E0E0' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#A6A6A6"} onChange={(e) => setColor(e.target.value)}></input>
                      <div className='colors' style={{ background: '#A6A6A6' }}></div>
                    </label>
                  </div>
                  <div className='the-edit m-auto text-center'>

                    <h4> צבע הסמל </h4>
                    <br></br>
                    <label>
                      <input type='text' name="color" className='more_option' onChange={(e) => setColorIcon(e.target.value)} placeholder='טקסט חופשי'></input>
                    </label>
                    <br></br>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#000"} onChange={(e) => setColorIcon(e.target.value)}></input>
                      <div className='colors' style={{ background: '#000' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#0d1a2f"} onChange={(e) => setColorIcon(e.target.value)}></input>
                      <div className='colors' style={{ background: '#0d1a2f' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#B11010"} onChange={(e) => setColorIcon(e.target.value)}></input>
                      <div className='colors' style={{ background: '#B11010' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#AA0E51"} onChange={(e) => setColorIcon(e.target.value)}></input>
                      <div className='colors' style={{ background: '#AA0E51' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#A40E75"} onChange={(e) => setColorIcon(e.target.value)}></input>
                      <div className='colors' style={{ background: '#A40E75' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#8B0EA4"} onChange={(e) => setColorIcon(e.target.value)}></input>
                      <div className='colors' style={{ background: '#8B0EA4' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#3B0EA4"} onChange={(e) => setColorIcon(e.target.value)}></input>
                      <div className='colors' style={{ background: '#3B0EA4' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#0E5B95"} onChange={(e) => setColorIcon(e.target.value)}></input>
                      <div className='colors' style={{ background: '#0E5B95' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#05663C"} onChange={(e) => setColorIcon(e.target.value)} ></input>
                      <div className='colors' style={{ background: '#05663C' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#3C5F07"} onChange={(e) => setColorIcon(e.target.value)}></input>
                      <div className='colors' style={{ background: '#3C5F07' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#5D5656"} onChange={(e) => setColorIcon(e.target.value)}></input>
                      <div className='colors' style={{ background: '#5D5656' }}></div>
                    </label>
                    <label>
                      <input className='check_color' type='radio' name="color" value={"#7E4815"} onChange={(e) => setColorIcon(e.target.value)}></input>
                      <div className='colors' style={{ background: '#7E4815' }}></div>
                    </label>


                  </div>
                  <hr></hr>
                  <div className='the-edit m-auto text-center'>
                    <div>
                      <h4>מיקום</h4>

                      <span className='location'>

                        <input type='range' min="0" max="91" value={top} onChange={(e) => setTop(e.target.value)}></input>

                        <label>
                          <input className='radio-style1 check_top' id="outline" type='radio' name="top" value={"top"} onChange={(e) => setTop(e.target.value)} ></input>
                          <span className='location_span'>
                            <i class="fa fa-arrow-up mx-1"></i>

                            למעלה
                          </span>
                        </label>
                        <label>
                          <input className='radio-style1 check_top' id="outline" type='radio' name="top" value={"bottom"} onChange={(e) => setTop(e.target.value)}></input>
                          <span className='location_span'>
                            <i class="fa fa-arrow-down mx-1"></i>

                            למטה
                          </span>
                        </label>
                      </span>
                    </div>
                    <span className='location'>
                      <label>
                        <input className='radio-style1 check_top' id="outline" type='radio' name="right" value={"right"} onChange={(e) => setRight(e.target.value)} ></input>
                        <span className='location_span'>
                          <i class="fa fa-arrow-right mx-1"></i>

                          ימין
                        </span>
                      </label>
                      <label>
                        <input className='radio-style1 check_top' id="outline" type='radio' name="right" value={"left"} onChange={(e) => setRight(e.target.value)}></input>
                        <span className='location_span'>
                          <i class="fa fa-arrow-left mx-1"></i>

                          שמאל
                        </span>
                      </label>
                    </span>
                  </div>
                  <hr></hr>
                  <div className='the-edit m-auto text-center'>
                    <h4>שפה</h4>
                    <label className='spee'>
                      <input className='radio-style1 check_language' id="outline" type='radio' name="language" value={"iw"} onChange={(e) => setLanguage(e.target.value)} ></input>
                      <span className='location_span'>
                        <img src='https://plugin.access4u.co.il/img/flag/i.png' className='img-fluid img_language'></img>

                        עברית
                      </span>
                    </label>
                    <label className='spee'>
                      <input className='radio-style1 check_language' id="outline" type='radio' name="language" value={"en"} onChange={(e) => setLanguage(e.target.value)}  ></input>
                      <span className='location_span'>
                        <img src='https://plugin.access4u.co.il/img/flag/e.png' className='img-fluid img_language'></img>

                        English
                      </span>
                    </label>
                    <label className='spee'>
                      <input className='radio-style1 check_language' id="outline" type='radio' name="language" value={"fr"} onChange={(e) => setLanguage(e.target.value)}></input>
                      <span className='location_span'>
                        <img src='https://plugin.access4u.co.il/img/flag/f.png' className='img-fluid img_language'></img>

                        Français
                      </span>
                    </label>
                    <label className='spee'>
                      <input className='radio-style1 check_language' id="outline" type='radio' name="language" value={"ar"} onChange={(e) => setLanguage(e.target.value)}></input>
                      <span className='location_span'>
                        <img src='https://plugin.access4u.co.il/img/flag/a.png' className='img-fluid img_language'></img>

                        العربية
                      </span>
                    </label>
                    <label className='spee'>
                      <input className='radio-style1 check_language' id="outline" type='radio' name="language" value={"ru"} onChange={(e) => setLanguage(e.target.value)}></input>
                      <span className='location_span'>
                        <img src='https://plugin.access4u.co.il/img/flag/r.png' className='img-fluid img_language'></img>

                        Русский
                      </span>
                    </label>
                  </div>
                  <hr></hr>


                </div>
                <div className='container-full '>
                  <h3 className='bg-start text-center'> הצהרת נגישות</h3>
                  <input type='text' name="name" className='statement_input_edit statement_input' onChange={(e) => setNameStatement(e.target.value)} value={nameStatement} ></input>
                  <input type='phone' name="phone" className='statement_input_edit statement_input' onChange={(e) => setPhoneStatement(e.target.value)} value={phoneStatement} ></input>

                  <input type='email' name="email" className='statement_input_edit statement_input' onChange={(e) => setEmailStatement(e.target.value)} value={emailStatement} ></input>

                  <textarea name='ddd' className='statement_textarea_edit statement_textarea' rows={5} onChange={(e) => setDataStatement(e.target.value)} value={dataStatement} ></textarea>

                </div>
              </div>
            </div>

            <footer className={classes.actions}>

              <button
                className='btn btn-secondary'
                value='submit'
                type='submit'>
                {' '}
                {isLoading ? <Spinner /> : `שמור`}{' '}
              </button>
              <a className='btn btn-primary' onClick={props.onConfirm}>
                סגור
              </a>
            </footer>
          </form>
        </div>
      </div>
    </div >
  );
};

export const AllStyle = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheStyle
          title={props.title}
          message={props.message}
          onConfirm={props.onConfirm}
        />,
        document.getElementById('modal')
      )}
    </Fragment>
  );
};
