import { useDispatch, useSelector } from 'react-redux';
import { TopNav } from '../../../Components/TopNav/TopNav';
import { Sidebar } from '../../Layout/Sidebar/Sidebar';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { Spinner } from '../../../Components/Spinner/Loader';
import { backendURL } from '../../../Components/backend';
import { Modal } from '../../../Components/Modal/Modal';
import { NotifyModal } from '../../../Components/Modal/NotifyModal';

export const AddNewUser = () => {
  const navbarShow = useSelector((state) => state.navbarToggle.show);
  const token = localStorage.getItem('token');
  const [isLoading, setLoading] = useState(false);
  const [AlreadyEmailAlert, setAlreadyEmailAlert] = useState(false);
  const [script, setScript] = useState(null);
  const [ShowModal, setShowModal] = useState(false);

  const [anyError, setanyErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const phoneRegExp =
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

  const formik = useFormik({
    initialValues: {
      Name: '',
      Phone: '',
      Email: '',
      Password: '',
      ConfirmPass: '',
    },
    validationSchema: Yup.object({
      Name: Yup.string()
        .max(20, 'Name Should be less than 20 charachers')
        .required('Required'),
      Phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Required'),
      Email: Yup.string().email().required('Required'),
      Password: Yup.string()
        .min(8, 'Minimum 8 Characters long')
        .required('Required'),
      ConfirmPass: Yup.string()
        .min(8, 'Minimum 8 Characters long')
        .oneOf([Yup.ref('Password')], 'Password did not match')
        .required('Required'),
    }),
    onSubmit: (values, { resetForm }) => {
      // if (
      //   values.domainName.includes('/') ||
      //   values.domainName.includes('http')
      // ) {
      //   if (values.domainName === '') {
      //     setErrorMessage(true);
      //     setErrorMessage('Please Enter Domain Name !');
      //   } else if (values.domainName.includes('http')) {
      //     setanyErrorMessage(true);
      //     setErrorMessage(
      //       'Please remove the protocols http/https. Type domain like "www.example.com"'
      //     );
      //   } else {
      //     setanyErrorMessage(true);
      //     setErrorMessage('Please remove all / backslashes  !');
      //   }
      // } else if (!values.domainName.includes('www')) {
      //   setanyErrorMessage(true);
      //   setErrorMessage('Incomplete Domain Name !');
      // } else {
      //   const editDomain = values.domainName.split('.');
      //   editDomain.shift();
      //   const finalDomain = editDomain.join('.');

        // if (feature === 'Select Feature') {
        //   setanyErrorMessage(true);
        //   setErrorMessage('Please Select Feature');
        // } else {
          setLoading(true);
          axios({
            method: 'POST',
            url: `${backendURL}/api/addAdminSiteAndClient `,
            data: {
              name: values.Name,
              phoneNo: values.Phone,
              password: values.Password,
              email: values.Email,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              if (res.status === 200 && res.data.error) {
                setLoading(false);
                setAlreadyEmailAlert(true);
              } else {
                setLoading(false);
                setScript(res.data);
                setShowModal(true);
                resetForm({ values: '' });
              }
            })
            .catch((e) => {
              setLoading(false);
              setanyErrorMessage(true);
              setErrorMessage(e.message) ||
                setErrorMessage(e.response.data.error);
            });
      }
    })
  

  const handleConfirm = () => {
    setShowModal(false);
  };

  return (
    <div className='wrapper'>
      <div className='dashboard-wrapper'>
        <div
          className={navbarShow ? 'sidebar px-md-3' : 'sidebar show px-md-3'}
        >
          <Sidebar> </Sidebar>
        </div>
        <div className='right-content'>
          <div className='content'>
            <TopNav />
            {ShowModal && (
              <NotifyModal
                title='Message'
                message={script}
                onConfirm={handleConfirm}
              />
            )}
            {/* =============== Inner Section Start ============= */}
            <div className='container-fluid'>
              <div className='row align-items-start'>
                <div className='col-md-12 m-auto'>
                  <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                    <h1 className='h3 mb-0 text-gray-800 text-center'>
                      Add New User
                    </h1>
                  </div>

                  {/* //========= Error Message ======== */}

                  <div className='col-md-12 m-auto'>
                    <div className='row '>
                      <div
                        className='px-md-3 m-auto text-left my-5'
                        style={{ width: '80%', textAlign: 'left' }}
                      >
                        {/* //===== Error Message Block  ====*/}

                        <div
                          className='alert alert-warning alert-dismissible fade show'
                          role='alert'
                          style={{
                            display: AlreadyEmailAlert ? 'block' : 'none',
                          }}
                        >
                          <strong>Email Already Registered !</strong> Try{' '}
                          <Link to='/login'>Login</Link> ?
                          <button
                            type='button'
                            className='close'
                            data-dismiss='alert'
                            onClick={() =>
                              setAlreadyEmailAlert(!AlreadyEmailAlert)
                            }
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>

                        {/* //========= Error Message ======== */}

                        <div
                          className='alert alert-warning alert-dismissible fade show'
                          role='alert'
                          style={{ display: anyError ? 'block' : 'none' }}
                        >
                          <strong>{errorMessage}</strong>
                          <button
                            type='button'
                            className='close'
                            onClick={() => setanyErrorMessage(!anyError)}
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>

                        {/* //===== Sign Up Form   ====*/}
                        <form onSubmit={formik.handleSubmit}>
                          <div className=' row'>
                            <div className='form-group col-lg-6 col-md-10 col-sm-12'>
                              <label> Name</label>
                              <input
                                type='text'
                                name='Name'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Name}
                                className='form-control'
                                placeholder='Enter the name'
                              ></input>
                              {formik.touched.Name && formik.errors.Name ? (
                                <p className='text-danger mt-1'>
                                  {formik.errors.Name}
                                </p>
                              ) : null}
                            </div>
                            <div className='form-group  col-lg-6 col-md-10 col-sm-12 '>
                              <label>Email</label>
                              <input
                                type='email'
                                name='Email'
                                value={formik.values.Email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className='form-control'
                                placeholder='Enter email'
                              ></input>
                              {formik.touched.Email && formik.errors.Email ? (
                                <p className='text-danger mt-1'>
                                  {formik.errors.Email}
                                </p>
                              ) : null}
                            </div>

                            <div className='form-group my-2 col-lg-6 col-md-10 col-sm-12'>
                              <label>
                                Phone{' '}
                                <span className='text-small pl-2 text-muted'>
                                  ( +972 55 44X-XXXX )
                                </span>
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                name='Phone'
                                placeholder='Enter phone'
                                value={formik.values.Phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              ></input>
                              {formik.touched.Phone && formik.errors.Phone ? (
                                <p className='text-danger mt-1'>
                                  {formik.errors.Phone}
                                </p>
                              ) : null}
                            </div>

                            {/* <div className='form-group col-lg-6 col-md-10 col-sm-12 my-2'>
                              <label>Domain Name</label>
                              <input
                                type='text'
                                name='domainName'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.domainName}
                                className='form-control'
                                placeholder='Domain Name'
                              ></input>
                              {formik.touched.domainName &&
                              formik.errors.domainName ? (
                                <p className='text-danger mt-1'>
                                  {formik.errors.domainName}
                                </p>
                              ) : null}
                            </div> */}
                            <div className='form-group  col-lg-6 col-md-10 col-sm-12'>
                              <label>Password</label>
                              <input
                                type='password'
                                name='Password'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Password}
                                className='form-control'
                                placeholder='Enter password'
                              ></input>
                              {formik.touched.Password &&
                              formik.errors.Password ? (
                                <p className='text-danger mt-1'>
                                  {formik.errors.Password}
                                </p>
                              ) : null}
                            </div>
                            <div className='form-group col-lg-6 col-md-10 col-sm-12'>
                              <label>Confirm Password</label>
                              <input
                                type='password'
                                name='ConfirmPass'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.ConfirmPass}
                                className='form-control'
                                placeholder='Confirm Password'
                              ></input>
                              {formik.touched.ConfirmPass &&
                              formik.errors.ConfirmPass ? (
                                <p className='text-danger mt-1'>
                                  {formik.errors.ConfirmPass}
                                </p>
                              ) : null}
                            </div>

                            {/* <div className='form-group col-lg-12 col-md-10 col-sm-12'>
                              <label>Select Feature</label>
                              <select
                                className='custom-form-control'
                                value={feature}
                                onChange={(e) => setFeature(e.target.value)}
                              >
                                <option value={'Select Feature'}>
                                  Select Feature
                                </option>
                               
                                <option value={'ALL_FEATURES'}>
                                  All Features
                                </option>
                              </select>
                            </div> */}

                            
                          </div>
                          <div className='form-group my-2 mx-auto text-center col-lg-6 col-md-10 col-sm-12'>
                              <button
                                className='btn btn-form btn-primary '
                                type='submit'
                                style={{ width: '100%' }}
                              >
                                {' '}
                                {isLoading ? <Spinner /> : `Add User`}{' '}
                              </button>
                            </div>  
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* =============== Inner Section End ============= */}
          </div>
        </div>
      </div>
    </div>
  );
};
