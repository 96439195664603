import axios from 'axios';
import { backendURL } from '../../Components/backend';
import { errorLoading, setGetAllSites } from './getAllSites';

export const Unpaid = () => async (dispatch) => {
  const getToken = localStorage.getItem('token');
  const email = localStorage.getItem('email');

  await axios({
    method: 'GET',
    url: `${backendURL}/api/getSites/${email}`,
    data: {},
    headers: {
      authorization: `Bearer ${getToken}`,
    },
  })
    .then((res) => {
      dispatch(setGetAllSites(res.data));
    })
    .catch((e) => {
      dispatch(errorLoading(e.message));
    });
};

export const allDashboardData = async (token) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${backendURL}/api/getAllPaidScripts`,
      data: {},
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const paid = await axios({
      method: 'GET',
      url: `${backendURL}/api/getAllUnpaidScripts`,
      data: {},
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return {
      paid: paid.data,
      unpaid: res.data,
    };
  } catch (error) {
  }
};
