import { Routes, Route } from 'react-router-dom';
import { Dashboard } from '../Pages/Dashboard';
import { PaymentPlans } from '../Pages/PaymentPlan/PaymentPlans';
import { ContactUs, Table } from '../Pages/ContactUs';
import { SignUp } from '../LoginSign/SignUp/SignUp';
import { Verify } from '../LoginSign/SignUp/Verify';
import { ThankYou } from '../LoginSign/SignUp/ThankYou';
import { AllUsers } from '../Pages/Users/AllUsers';
import { UnPaid } from '../Pages/Unpaid/Unpaid';
import { Paid } from '../Pages/Paid/Paid';
import { AllScripts } from '../Pages/AllScripts/AllScripts';
import AddNewSite from '../Pages/NewSite/AddNewSite';
import { AddNewUser } from '../Pages/AddNewUser/AddNewUser';
import FreeSites from '../Pages/FreeSites/FreeSites';
import Paused from '../Pages/Paused/Paused';
import CanceledSites from '../Pages/Canceled/Canceled';
// import AllUsers from '../Pages/Users/New';

export const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard />}></Route>
      <Route path='/dashboard' element={<Dashboard />}></Route>
      <Route path='/allusers' element={<AllUsers />}></Route>
      <Route path='/unpaid' element={<UnPaid />}></Route>
      <Route path='/freeSites' element={<FreeSites />}></Route>
      <Route path='/paid' element={<Paid />}></Route>
      <Route path='/paused' element={<Paused />}></Route>
      <Route path='/canceled' element={<CanceledSites/>}></Route>
      <Route path='/allscripts' element={<AllScripts />}></Route>
      <Route path='/addnewuser' element={<AddNewUser />}></Route>
      <Route path='/addnewsite' element={<AddNewSite />}></Route>

      {/* <Route path='/allsites' element={<AllSites/>}></Route> */}
      <Route path='/paymentplans' exact element={<PaymentPlans />}></Route>
      <Route path='/contactus' exact element={<ContactUs />}></Route>
      {/* <Route path='/getscript/:domainName' exact element={<GetScript/>}></Route> */}
      <Route
        path='/paymentplans/:domain'
        exact
        element={<PaymentPlans />}
      ></Route>
      <Route path='/signup' element={<SignUp />}></Route>
      <Route path='/verify/:id' element={<Verify />}></Route>
      <Route path='/thankyou' element={<ThankYou />}></Route>
      <Route path='*' element={<Dashboard />}></Route>
    </Routes>
  );
};
